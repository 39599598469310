
//import localStorage
import { removeAuthToken,removeRefferAddress,removeWallteAddress,setTheme as setThemeLocal, } from "../lib/localStorage"

//import constant
import {
    ISAUTH,
    profile_Data,
    WALLET_ADDRESS,
    CURRENCY_DATA,
    SET_THEME,
    NOTICE_DATA
} from '../constant'

export const logOut = (dispatch)=>{
    try{
        dispatch({
           type:ISAUTH,
           payload:false 
        })
        dispatch({
            type:profile_Data,
            payload:{} 
         })
         dispatch({
            type:WALLET_ADDRESS,
            payload:"" 
         })
         dispatch({
            type:CURRENCY_DATA,
            payload:"" 
         })
         dispatch({
            type: NOTICE_DATA,
            payload:"" 
         })
        
        removeAuthToken()
        removeWallteAddress()
        removeRefferAddress()
    }catch(err){
        console.log(err,"logOut___err")
    }
}

export const setTheme = async (dispatch, theme) => {
    await setThemeLocal(theme);
    dispatch({
        type: SET_THEME,
        theme
    })
    return true
}