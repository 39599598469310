var EnvName = "demo";
var key = {};

if (EnvName == "live") {
  key.FRONT_URL = "https://swipechain.trade/";
  key.BACK_URL = "https://api.swipechain.trade/";
  key.secretOrKey = "yaz";
  key.SOCKET_URL = "https://api.swipechain.trade/";
  key.Image_URL = "'https://api.swipechain.trade/";
  // key.BACK_URL        =   'http://192.53.121.26:3331/v1/front'
  key.ADMIN_URL = "https://contorls.swipechain.trade/";
} else if (EnvName == "demo") {
  key.FRONT_URL = "https://swipe-arbitrage.maticz.in/";
  key.BACK_URL = "https://backend-swipearbitrage.maticz.in";
  key.SOCKET_URL = "https://backend-swipearbitrage.maticz.in/";
  key.secretOrKey = "yaz";
  key.Image_URL = "https://backend-swipearbitrage.maticz.in/";
  // key.Image_URL = "http://localhost:2053/"
  key.ADMIN_URL = "https://swipe-arbitrageadmin.maticz.in/";
} else {
  // http://200.140.70.93:3000
  key.FRONT_URL = "http://localhost:3000"; //'http://localhost:3000'
  key.BACK_URL = "http://localhost:2078"; //'http://200.140.70.93:2078'//'http://localhost:2078'
  key.SOCKET_URL = "http://localhost:2078"; //'http://200.140.70.93:2078',// 'http://localhost:2078'
  key.secretOrKey = "yaz";
  // key.Image_URL = "http://localhost:2053/"
  // key.Image_URL = "http://localhost:2053/"
  // key.ADMIN_URL        =   'http://192.53.121.26:3331/v1/admin'
}
export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0";
export const CRYPTOCOMPARE =
  "03018fc2ff4df50c72ba3114a9735036ef486c7c85bec47b5796961ec976d54e";
// export const BUYCONTRACTADDRESS = "0x9F5fb585967cba2efF1462379e92817a0D685e3a";//0x9F5fb585967cba2efF1462379e92817a0D685e3a //"0xf4e912869FA9b8709df80AB35d7f9C2A70a34F44" //testnet
// export const BUYCONTRACTADDRESS ="0x9a78291429d7aFB749a723bA0147815E55124DdC" //"0x1DD2B0742806d20d7454c071a4142694762DC549"  // "0x4fEaE5448daa85d2Fae555f349AFD2fE2Ba7d8EE" testnet

export const BUYCONTRACTADDRESS = "0x9F5fb585967cba2efF1462379e92817a0D685e3a"; //testnet
export const LOCKEDSTAKECONTRACT = "0x1C3F02eD8e55dce5fB7356D7da5328dd1f7077E2"; //testnet
export const LAUNCHPADCONTRACT = "0xbfAFD82577259b4e4919b8b93e34EfC51B7F0531"; //testnet

//testnet ABI
export const LOCKEDSTAKECONTRACTABI = [
  {
    inputs: [
      { internalType: "contract MetaToken", name: "_meta", type: "address" },
      { internalType: "address", name: "_devaddr", type: "address" },
      { internalType: "address", name: "_feeAddress", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      { indexed: true, internalType: "uint256", name: "pid", type: "uint256" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Claim",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      { indexed: true, internalType: "uint256", name: "pid", type: "uint256" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      { indexed: true, internalType: "uint256", name: "pid", type: "uint256" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "EmergencyWithdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "user", type: "address" },
      { indexed: true, internalType: "uint256", name: "pid", type: "uint256" },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    inputs: [],
    name: "BONUS_MULTIPLIER",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_allocPoint", type: "uint256" },
      { internalType: "contract IBEP20", name: "_lpToken", type: "address" },
      { internalType: "uint16", name: "_depositFeeBP", type: "uint16" },
      { internalType: "bool", name: "_withUpdate", type: "bool" },
      { internalType: "bool", name: "_onlyOwners", type: "bool" },
      { internalType: "uint256", name: "_lockedPeriod", type: "uint256" },
      { internalType: "uint256", name: "_MetaPerBlock", type: "uint256" },
    ],
    name: "add",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_addr", type: "address" }],
    name: "changeTokenOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_pid", type: "uint256" }],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_user", type: "address" },
    ],
    name: "depositFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_devaddr", type: "address" }],
    name: "dev",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "devaddr",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_pid", type: "uint256" }],
    name: "emergencyWithdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "feeAddress",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "fixedAPR",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_from", type: "uint256" },
      { internalType: "uint256", name: "_to", type: "uint256" },
    ],
    name: "getMultiplier",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "intervalBlock",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "massUpdatePools",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "meta",
    outputs: [
      { internalType: "contract MetaToken", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "noOfUsers",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "address", name: "_user", type: "address" },
    ],
    name: "pendingMeta",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "poolInfo",
    outputs: [
      { internalType: "contract IBEP20", name: "lpToken", type: "address" },
      { internalType: "uint256", name: "allocPoint", type: "uint256" },
      { internalType: "uint256", name: "lastRewardBlock", type: "uint256" },
      { internalType: "uint16", name: "depositFeeBP", type: "uint16" },
      { internalType: "bool", name: "emergencyWithdrawnable", type: "bool" },
      { internalType: "bool", name: "onlyOwneraccess", type: "bool" },
      { internalType: "uint256", name: "lockedPeriod", type: "uint256" },
      { internalType: "uint256", name: "MetaPerBlock", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "poolLength",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "uint256", name: "_allocPoint", type: "uint256" },
      { internalType: "uint16", name: "_depositFeeBP", type: "uint16" },
      { internalType: "bool", name: "_withUpdate", type: "bool" },
      { internalType: "bool", name: "_onlyOwners", type: "bool" },
      { internalType: "uint256", name: "_lockedPeriod", type: "uint256" },
      { internalType: "uint256", name: "_MetaPerBlock", type: "uint256" },
    ],
    name: "set",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "setEmergencyWithdrawnable",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_feeAddress", type: "address" }],
    name: "setFeeAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_interval", type: "uint256" }],
    name: "setIntervalBlock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "startBlock",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalAllocPoint",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "uint256", name: "_MetaPerBlock", type: "uint256" },
    ],
    name: "updateEmissionRate",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_pid", type: "uint256" }],
    name: "updatePool",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
    ],
    name: "userInfo",
    outputs: [
      { internalType: "uint256", name: "amount", type: "uint256" },
      { internalType: "uint256", name: "rewardDebt", type: "uint256" },
      { internalType: "uint256", name: "lastRewardBlock", type: "uint256" },
      { internalType: "uint256", name: "pendingRewards", type: "uint256" },
      { internalType: "uint256", name: "lastClaim", type: "uint256" },
      { internalType: "uint256", name: "userLockedPeriod", type: "uint256" },
      { internalType: "bool", name: "isExists", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_addr", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" },
    ],
    name: "withdrawSafe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
export const LAUNCHPADABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_from",
        type: "address",
      },
      { indexed: true, internalType: "address", name: "_to", type: "address" },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "_presale",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_tokenAddress", type: "address" },
      { internalType: "uint256", name: "_tokenRatePerEth", type: "uint256" },
      { internalType: "uint256", name: "_tokenRatePercrypto", type: "uint256" },
      { internalType: "uint256", name: "_maxEthLimit", type: "uint256" },
      { internalType: "uint256", name: "_minEthLimit", type: "uint256" },
      { internalType: "uint256", name: "_BUSDmaxEthLimit", type: "uint256" },
      { internalType: "uint256", name: "_BUSDminEthLimit", type: "uint256" },
      { internalType: "uint256", name: "_hardCap", type: "uint256" },
      { internalType: "uint256", name: "_BUSDhardCap", type: "uint256" },
      { internalType: "uint256", name: "_poolId", type: "uint256" },
      { internalType: "address", name: "_staker", type: "address" },
    ],
    name: "createPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "getPresale",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address payable", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address payable", name: "_newOwner", type: "address" },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export const PRESALEABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_tokenRatePerEth",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_tokenRatePercrypto",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_maxEthLimit",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_minEthLimit",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_BUSDmaxEthLimit",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_BUSDminEthLimit",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_hardCap",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_BUSDhardCap",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_poolId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_staker",
        type: "address",
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "_to",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "BUSDearnedCap",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BUSDhardCap",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BUSDmaxEthLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "BUSDminEthLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Staker",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "_crypto",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_addresses",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_allocation",
        type: "uint256[]",
      },
    ],
    name: "addMultipleWhitelistedAddresses",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_address",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_allocation",
        type: "uint256",
      },
    ],
    name: "addWhitelistedAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_staker",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "approveContarct",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "burnUnsoldTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "buyToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "closePresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "currentPoolId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_pid",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "earnedCap",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "endTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_staker",
        type: "address",
      },
    ],
    name: "finalizeSale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "getTokenPerCrypto",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "getTokensPerEth",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getUnsoldTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getUnsoldTokensBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserBUSDInvestments",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserClaimbale",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserInvestments",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "hardCap",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "intervalDays",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isClaimable",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isPresaleOpen",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isWhitelisted",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "iscrypto",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxEthLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minEthLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rateDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_address",
        type: "address",
      },
    ],
    name: "removeWhitelistedAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_BUSDhardCap",
        type: "uint256",
      },
    ],
    name: "setBUSDHardCap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "setBUSDMaxEthLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "setBUSDMinEthLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "setCryptoAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_pid",
        type: "uint256",
      },
    ],
    name: "setCurrentPoolId",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_hardCap",
        type: "uint256",
      },
    ],
    name: "setHardCap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "setMaxEthLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "setMinEthLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "decimals",
        type: "uint256",
      },
    ],
    name: "setRateDecimals",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "setTokenAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "decimals",
        type: "uint256",
      },
    ],
    name: "setTokenDecimals",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rate",
        type: "uint256",
      },
    ],
    name: "setTokenRatePerEth",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "rateBUSD",
        type: "uint256",
      },
    ],
    name: "setTokenRatePercrypto",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "soldTokens",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "numberOfdays",
        type: "uint256",
      },
    ],
    name: "startPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenDecimals",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenRatePerEth",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenRatePercrypto",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "usersBUSDInvestments",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "usersInvestments",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "whitelistedAddresses",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "whitelistedSale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawBNB",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

export const Tokens = ["USDT", "USDC", "DAI", "BUSD"];
export const CHAINS = [
  {
    NAME: "BSC-TEST",
    RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
    CHAIN_ID: 97,
    ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    BUSD: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    // USDT: "0x1BCb9024d049BBe3dF15366b844826B2231B0833",
    USDT: "0x54C09A5856B08D795079cB1Ca61f55afD85Be39a",
    USDC: "0x0A1F4c4E14Dd83018942c5813768A8bf70b78599",
    DAI: "0xeDDC82488ecf00aC8CD8aD942AB3632E41424820",
    BUYCONTRACTADDRESS: "0xA13A80c68eBE9f7069B3D680B642C475Db843b70",
    TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
    NATIVE: "0xAA7d634a24Fe2Bb9DdCC3eDb8f5938F774761647", //SWIPE TOKEN
  },
  // {
  //     NAME: "BNB Smart Chain Mainnet",
  //     RPC_URL:"https://bsc-dataseed1.binance.org/",
  //     CHAIN_ID:56,
  //     ROUTER:"0x016073980B9C355Ab36de19E4b4030A1c9409603", //"0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
  //     // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
  //     BNB : "0x0000000000000000000000000000000000000000",
  //     BUSD : "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  //     USDT : "0x55d398326f99059fF775485246999027B3197955",
  //     USDC : "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  //     DAI:"0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
  // },
  // {
  //     NAME: "TRON-MANI",
  //     RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
  //     CHAIN_ID: "0x2b6653dc",
  //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
  //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  // {
  //     NAME: "TRON-SHASTA",
  //     RPC_URL: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC TESTNET
  //     CHAIN_ID: "0x94a9059e",
  //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
  //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  // {
  //     NAME: "TRON-NILE",
  //     RPC_URL: "https://nile.trongrid.io/jsonrpc/", // BSC TESTNET
  //     CHAIN_ID: "0xcd8690dc",
  //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //     // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
  //     MASTERCHEF: "0x62404d901467494eC5995CD279b7F8b30AfFf751",
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // }
  // {
  //   NAME: "AVALANCHE",
  //   RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc", // ETH TESTNET
  //   CHAIN_ID: 43113,
  //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //   MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",

  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  //   {
  //     NAME: "Polygon-TEST",
  //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
  //     CHAIN_ID:80001,
  //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
  //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new
  //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  //   {
  //     NAME: "BSC-MAINNET",
  //     RPC_URL:"https://bsc-dataseed1.binance.org/",
  //     CHAIN_ID:56,
  //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781",
  //     MASTERCHEF: "0x825A21c5A32d22b1241C7672EC9D04025960f766",
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  // {
  //     NAME: "BSC-MAINNET",
  //     RPC_URL: "https://bsc-dataseed1.binance.org/",
  //     CHAIN_ID: 56,
  //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781", //irea
  //     MASTERCHEF: "0x5fb7846a3fD122De5feb59bb1Ef67F742a6804d8",
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
  //     USDT: "0x55d398326f99059ff775485246999027b3197955",
  //     USDI: "0x91c7eBbDcbdE50559FDA424dd7Dc9AF142B9Ed58"
  // },
];

export { key };
