// import constant
import {
    CHART_DATA,
    
} from '../constant';

const initialValue = {
    Chartdata:[]
}

//  console.log("initialValue----->>>",initialValue);
const chart = (state = initialValue, action) => {

    switch (action.type) {
        case CHART_DATA:
            return {
                ...state,
                Chartdata: action.payload
            }
           
        default:
            return state;
    }
}

export default chart;