import { ERC20_ABI } from "../config/Erc20";

import { UseWeb3,useWeb3 } from "./useWeb3";

import { getWalletAddress } from "../lib/localStorage";

import { BUYCONTRACTADDRESS } from "../config/config";

export const UseERC20 = async (Token) => {
    const web3 = await useWeb3();
    try {
            console.log(Token,"UseERC20__err")
            const contract = new web3.eth.Contract(ERC20_ABI, web3.utils.toChecksumAddress(Token));
            return contract;
    } catch (err) {
        console.log(err, "UseERC20__err")
    }
}

export const checkIsApproved = async (account, Token,amount) => {
    try {
        const erc20contract = await UseERC20(Token);
        const allow = await erc20contract.methods.allowance(account, BUYCONTRACTADDRESS).call();
        console.log(parseInt(allow) >= amount,'checkIsApproved')
        return (parseInt(allow) >= amount) ? true : false;
    } catch (err) {
        console.log("checkIsApproved", err)
    }
}

export const Balance  = async (Token,address) => {
    const web3 = await useWeb3()
    try {
        let contract = await UseERC20(Token)
        let balance = await contract.methods.balanceOf(web3.utils.toChecksumAddress(address)).call()
        return balance
    } catch (err) {
        console.log('Xcontract__err', err, true)
        return false
    }
}
// export const EstGas = async (tokenAddress, toAddress, amount, fromAddress) => {
//     try {
//         const contract =await UseERC20(Token);
//         const web3 = await UseWeb3();
//         const TokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress)
//         var sendamount = amount
//         console.log('sendamount_', sendamount);
//         let encoded = await TokenContract.methods.transfer(
//             toAddress,
//             sendamount.toString()
//         ).encodeABI();
//         return encoded
//         // let cost = await  web3.eth.estimateGas({
//         //     from: fromAddress,
//         //     toAddress,
//         //     encoded
//         // })
//         // console.log('EstGas',await web3.eth.getGasPrice(), !isEmpty(cost) ?  ((await web3.eth.getGasPrice() * cost) / 10**18):0);
//         // return !isEmpty(cost) ?  ((await web3.eth.getGasPrice() * cost) / 10**18):0
//     } catch (e) {
//         console.log('EstGas_err', e);
//         return {
//             status: false
//         }
//     }
// }
export const Approvetoken = async (Token, amount) => {
    const web3 =await useWeb3()
    try {
        console.log("address",Token, amount);
        // const web3 =await UseWeb3()
        const gasPrice = await web3.eth.getGasPrice();
        
        const account = getWalletAddress();
        const contract =await UseERC20(Token);
        let encoded = await contract.methods.approve(
            web3.utils.toChecksumAddress(BUYCONTRACTADDRESS),
            amount
        ).encodeABI();
        let estimatedGasLimit = await web3.eth.estimateGas({
            from: web3.utils.toChecksumAddress(account) ,
            to: Token,
            data: encoded,
        });
        let gasLimit = estimatedGasLimit.toString()+'0'
        console.log(contract,estimatedGasLimit,gasPrice,"contract")
        const approve = await contract.methods.approve(web3.utils.toChecksumAddress(BUYCONTRACTADDRESS),amount).send({ from: web3.utils.toChecksumAddress(account) ,gasLimit: parseInt(estimatedGasLimit* 1.5,10),gasPrice:gasPrice})
        // await toast.promise(data, {
        //     loading: `Approving ${symbol} token...`,
        //     success: 'Approved Successfully',
        //     error: 'Try Again',
        // }, {
        //     position: position.position,
        //     style: style,
        //     iconTheme: iconTheme,
        // })
        // alert('Approved sccussefully')
        if(approve){
            return true;
        }
        return false
    }
    catch (err) {
        console.log("Approvetoken__err", err)
        return false
    }
}
 