import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import UserLogo from "../Assets/Img/user_logo.png";
import Form from "react-bootstrap/Form";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import BogBot from "../Assets/Img/alien.png";
import { jwtDecode } from "jwt-decode";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { isEmpty } from "../config/common";
import { key } from "../config/config";
import Select from "react-select";
import {
  fieldsubmit,
  EditEmail,
  userPlanActive,
  getWallet,
  getSiglePlanDetails,
  getUserProfitData,
  userProfitClaim,
  userPlanUpgrade,
} from "../api/api";
import { fileObjectUrl } from "../lib/fileobject";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegCopy } from "react-icons/fa";
import copy from "copy-to-clipboard";
import { toastAlert } from "../lib/toastAlert";
import { useDispatch, useSelector } from "react-redux";

import { getuserPlan, BotSubscribe, UserRecharge } from "../hooks/UseContract";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { getAuthToken, getWalletAddress } from "../lib/localStorage";
import { getPoolInfoLimit, getPoolLength, getUser } from "../hooks/useStake";

function DashboardContent() {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValue = {
    name: "",
  };
  const data = {
    newEmail: "",
  };
  const now = 60;
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        <div className="mb-2">
          <span>
            <span className="me-2">Active</span> :
            <span className="ms-2">
              {props &&
              props?.item?._enable == true &&
              props?.item?._isExpriy == false ? (
                <span>Active</span>
              ) : (
                <></>
              )}
              {props?.item?._enable == false &&
              props?.item?._isExpriy == false ? (
                <span>Not Active</span>
              ) : (
                <></>
              )}
            </span>
          </span>
        </div>

        <div className="mb-2">
          <span>
            <span className="me-2">Expriy</span> :
            <span className="ms-2">
              {props &&
              props?.item?._enable == false &&
              props?.item?._isExpriy == true ? (
                <span>Plan expired</span>
              ) : (
                <span>Not expired </span>
              )}
            </span>
          </span>
        </div>

        {/* <div className="mb-2">
          <span><span className="me-2">Duration</span> : <span className="ms-2">{duration}days</span></span>
        </div> */}
      </Tooltip>
    );
  };
  const [show, setShow] = useState(false);
  const [userPlans, setuserPlan] = useState([]);
  const [editEmail, setEditEmail] = useState(false);
  // const [tokendetails, setTokendetails] = useState({});
  const [formValue, setFormValue] = useState(initialValue);
  const [email, setEmail] = useState(data);
  const [validErrors, setValidErrors] = useState({});
  // const [profilelist, setProfilelist] = useState({})
  // const [file, setFiles] = useState("")
  // const [details, setDetails] = useState({})
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditEmailShow = () => {
    setEditEmail(true);
  };
  const handleEditEmailClose = () => setEditEmail(false);
  const { userDetails, userPlan } = useSelector((state) => state.user);
  const { walletaddress } = useSelector((state) => state.wallet);

  const { plans, Contractplans } = useSelector((state) => state.plan);

  const [redeemShow, setRedeemShow] = useState(false);
  const [depositShow, setDepositShow] = useState(false);
  const [claimShow, setClaimShow] = useState(false);
  const [walletData, setWalletData] = useState();
  const [amount, setAmount] = useState();
  const [userProfit, setProfit] = useState();
  const [userID, setUserId] = useState();
  const [todayProfit, setTodayProfit] = useState();

  const [reedemShow, setReedemShow] = useState(false);

  const handleReedemClose = () => setReedemShow(false);
  const handleReedemShow = () => setReedemShow(true);

  const [planId, setPlanId] = useState();

  const handleRedeemClose = () => {
    setRedeemShow(false);
  };
  const handleRedeemShow = () => setRedeemShow(true);
  const [pools, setPools] = useState();
  const [poolId, setPoolId] = useState("");
  const [planid, setplanid] = useState("");

  const handleDepositClose = () => setDepositShow(false);
  const handleDepositShow = () => setDepositShow(true);

  const handleClaimClose = () => setClaimShow(false);
  const handleClaimShow = () => setClaimShow(true);

  useEffect(() => {
    getWalletData();
    userProfitDetails();
    if (plans?.length > 0) {
      getUserPlans();
      getData();
    }
  }, [plans]);

  const getData = async () => {
    var data = await getPoolLength();
    if (data) var pool = await getPoolInfoLimit(0, data);
    console.log(pool, "pool");

    pool.map(
      (val, index) => (
        (val.label = val.lockedPeriod / 86400), (val.value = `${index}`)
      )
    );

    setPools(pool);
  };

  const getUserPlans = async () => {
    try {
      let UserPlan = [];
      for (let i = 0; i < plans.length; i++) {
        let account = getWalletAddress();
        console.log("🚀 ~ getUserPlans ~ account:", account)
        let userPlan = await getuserPlan(i, account);
        console.log("🚀 ~ getUserPlans ~ userPlan:", userPlan)
        if (parseFloat(userPlan._buyTime) > 0) {
          UserPlan.push(userPlan);
        }
        if (plans.length - 1 == i) {
          setuserPlan(UserPlan);
        }
      }
    } catch (err) {
      console.log(err, "getUserPlans__err");
    }
  };
  const UserBotSubscribe = async (planId, poolId) => {
    console.log("🚀 ~ UserBotSubscribe ~ planId, poolId:", planId, poolId);
    try {
      let poolID = poolId;
      let planID = planId;
      let planSubscribe = await BotSubscribe(poolID, planID);
      if (planSubscribe.status == true) {
        let { status, message } = await userPlanActive({
          _id: planid,
          userId: userID,
        });
        if (status == true) {
          toastAlert("success", message);
          history.push("/locked-staking");
        } else {
          toastAlert("error", message);
        }
      }
    } catch (err) {
      console.log(err, "UserBotSubscribe__err");
    }
  };
  const getWalletData = async () => {
    let walletData = await getWallet(dispatch);
    setWalletData(walletData.result);
    setUserId(walletData.result.userId);
  };
  const calculateFuel = (max, depositMax) => {
    if (walletData.assets[0].balance == 0) {
      let fuelPercentage = 0;
      let tradeAmount = 0;
      fuelPercentage = 0;
      return { fuelPercentage, tradeAmount };
    } else {
      let fuelPercentage = (walletData.assets[0].balance / depositMax) * 100;
      let tradeAmount = (depositMax * fuelPercentage) / 100;
      fuelPercentage = (max * fuelPercentage) / 100;
      return { fuelPercentage, tradeAmount };
    }
  };
  const userRecharge = async (data) => {
    try {
      let resData = await UserRecharge(data);
      if (resData.status) {
        toastAlert("success", "Deposit Successfully");
        getUserPlans();
      }
    } catch (err) {
      console.log("userRecharge_err", err);
    }
  };
  const userProfitDetails = async () => {
    try {
      let resData = await getUserProfitData({ userId: userID });
      if (resData.status) {
        setProfit(resData?.result);
        setTodayProfit(resData?.todayProfit);
      }
    } catch (err) {
      console.log("userRecharge_err", err);
    }
  };
  const userClaim = async (data) => {
    try {
      let resData = await userProfitClaim(data);
      if (resData.status) {
        toastAlert("success", "Claim Successfully");
        getUserPlans();
      }
    } catch (err) {
      console.log("userRecharge_err", err);
    }
  };

  const planUpgrade = async (_id) => {
    try {
      let { status, message, result } = await userPlanUpgrade({ _id: _id });
      console.log(status, message, result, "status, message, result");
      if (status == "success" && result.length > 1) {
        toastAlert("success", "Reedem Another Plan To Upgrade");
        getUserPlans();
      }
      if (result?.length == 1) {
        toastAlert("success", "Purchase Another Plan To Upgrade");
        history.push("/purchase-bot");
      }
    } catch (err) {
      console.log(err, "planUpgrade_err");
    }
  };

  useEffect(() => {
    if (userDetails) {
      setFormValue(userDetails);
    }
  }, [userDetails]);

  const options = {
    loop: true,
    center: true,
    items: 1,
    margin: 0,
    // autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: userPlan?.purchaseDetails?.length > 1 ? true : false,
    // navigation: true,
    // navigationText: [
    //   "<i class='fa fa-angle-left'></i>",
    //   "<i class='fa fa-angle-right'></i>",
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const PurchaseBot = [
    {
      logo: BogBot,
      amount: "20",
      trade: "10-200",
      activate: " Activate Bot",
    },
    {
      logo: BogBot,
      amount: "50",
      trade: "20-500",
      activate: " Activate Bot",
    },
    {
      logo: BogBot,
      amount: "100",
      trade: "20-1000",
      activate: " Activate Bot",
    },
  ];

  const handlechange = (e) => {
    try {
      setValidErrors({});
      const { name, value } = e.target;
      // setProfilelist({ ...profilelist, [name]: value });
      setFormValue({ ...formValue, [name]: value });
    } catch (err) {
      console.log(err, "handlechange");
    }
  };

  const onChangeValue = async (e) => {
    setValidErrors({});
    var { name, value, files } = e.target;
    if (name === "upload") {
      // setFiles(files[0]);
      // setProfilelist({ ...profilelist, ...{ [name]: value } })
      setFormValue({ ...formValue, ...{ profilepic: files[0] } });
    }
  };

  const onChangefile = async (e) => {
    try {
      var { name, value, files } = e.target;
      let validErrors = {};
      if (!/\.(gif|jpeg|tiff|png|webp|bmp|jpg)$/i.test(files[0]?.name)) {
        validErrors.file =
          "Please upload file having extensions  .png,.jpeg,.jpg only.";
      }
      if (Object.keys(validErrors).length > 0) {
        setValidErrors(validErrors);
      } else {
        if (name === "upload") {
          setFormValue({ ...formValue, ...{ profilepic: files[0] } });
        }
        var passData = new FormData();
        passData.append("file", files[0]);
        var update = await fieldsubmit(passData, dispatch);
        if (update.status == "success") {
          toast.success(update.message);
        }
      }
    } catch (err) {
      console.log(err, "files[0]");
    }
  };

  const validation = async (data) => {
    let validErrors = {};
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (isEmpty(data?.name)) {
      validErrors.name = "name field is required";
    }
    if (typeof data?.profilepic == "object") {
      if (isEmpty(data?.profilepic.name?.match(allowedExtensions))) {
        validErrors.file =
          "Please upload file having extensions  .png,.jpeg,.jpg only.";
      }
    }

    return validErrors;
  };

  const handleSubmit = async () => {
    var value = await validation(formValue);
    if (!isEmpty(value)) {
      setValidErrors(value);
    } else {
      var passData = new FormData();
      passData.append("file", formValue?.profilepic);
      passData.append("name", formValue?.name);
      var adddata = await fieldsubmit(passData, dispatch);
      if (adddata.status == "success") {
        toast.success(adddata.message);
        handleClose();
      }
    }
  };

  const copyToClipboard = () => {
    copy(formValue.walletaddress);
    toast.success("Wallet address copied Successfully", {
      style: {
        minWidth: "300px",
        minHeight: "55px",
      },
    });
  };

  const handleChange = (e) => {
    setValidErrors({});
    e.preventDefault();
    const { name, value } = e.target;
    let formDatas = { ...email, ...{ [name]: value } };
    setEmail(formDatas);
  };

  const selectChange = (e) => {
    setPoolId(e.value);
  };
  const validations = async (data) => {
    let validErrors = {};
    let emailRegex =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (isEmpty(data?.newEmail)) {
      validErrors["newEmail"] = "Email field is required";
    } else {
      if (typeof data == "object") {
        if (isEmpty(data?.newEmail.match(emailRegex))) {
          validErrors["newEmail"] = "invalid format";
        }
      }
    }
    setValidErrors(validErrors);
    return validErrors;
  };

  const changemailSubmit = async (e) => {
    e.preventDefault();
    try {
      var values = await validations(email);
      if (!isEmpty(values)) {
        setValidErrors(values);
      } else {
        var reqData = {
          newEmail: email.newEmail,
        };
        let { status, loading, error, message, result } = await EditEmail(
          reqData
        );
        if (status) {
          toastAlert("success", message);

          setValidErrors({});
          handleEditEmailClose();
        } else {
          if (error) {
            setValidErrors(error);
          } else if (message) {
            toastAlert("error", message);
          }
        }
      }
    } catch (err) {}
  };

  return (
    <div className="content_section mt-5">
      <p className="profile">Profile</p>
      <div className="row profile-sec">
        <div className="col-xl-4 col-lg-5 col-md-12 col-sm-8 col-md-12 mb-lg-0 mb-xl-0 mb-4">
          <div>
            <Card className="user_profile_card">
              <Card.Body>
                <div className="user_profile">
                  <span className="position-relative">
                    <img
                      className="img-fluid"
                      height="100"
                      width="100"
                      src={
                        formValue.profilepic == ""
                          ? UserLogo
                          : fileObjectUrl(formValue.profilepic)
                      }
                    />
                  </span>
                </div>
                <div class="upload-btn-wrapper mt-3">
                  <button class="btn">Upload Profile</button>
                  <input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={(event) => onChangefile(event)}
                  />
                  <p className="validErrors_style" id="upload-validErrors">
                    {validErrors && validErrors.file}
                  </p>
                </div>
                <div className="user_details mt-4">
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="user_detail_name">User Name</p>
                    </div>
                    <div className="col-12">
                      <p className="user_detail_op">{formValue.name}</p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <p className="user_detail_name">Email Id</p>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-10">
                          <p className="user_detail_op">{formValue.email}</p>
                        </div>
                        <div
                          className="col-2 ps-0 edit_icn_btn"
                          onClick={handleEditEmailShow}>
                          <i class="fa-solid fa-pen"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12">
                      <p className="user_detail_name">Wallet Address</p>
                    </div>
                    <div className="col-12">
                      <p className="user_detail_op">
                        {formValue.walletaddress ? (
                          <div className="tokens">
                            <p>
                              {
                                <>
                                  {(formValue?.walletaddress).slice(0, 7)}...
                                  {(formValue?.walletaddress).slice(
                                    (formValue?.walletaddress).length - 9,
                                    (formValue?.walletaddress).length - 1
                                  )}
                                  <FaRegCopy
                                    className="ms-2"
                                    onClick={copyToClipboard}
                                  />
                                </>
                              }
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <Button
                  variant="primary"
                  type="button"
                  size="lg"
                  className="btn common_green_Btn sign-up-btn btn-block w-100"
                  onClick={handleShow}>
                  Edit
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="col-xl-5 col-xxl-4 offset-xl-1 col-lg-6 offset-lg-1 col-md-8 offset-md-1 col-sm-8 col-12">
          <div className="purchase-bot mt-0 pt-0 w-100">
            {userPlans?.length > 0 ? (
              userPlans?.length == 1 ? (
                userPlans?.map((item, i) => {
                  var planData = isEmpty(Contractplans)
                    ? {}
                    : Contractplans[item._planID];
                  let DBPlan = plans?.find((val) => val.planId == item._planID);
                  planData = { ...planData, ...DBPlan };
                  let userFuel = calculateFuel(
                    planData.maxLimit,
                    planData.maxDeposit
                  );
                  let _id = userPlan.purchaseDetails.find(
                    (val) => val.planId == planData._id
                  );
                  let isActivePlan = userPlan.purchaseDetails.find(
                    (val) => val.isActivate == true
                  );
                  let userId = userPlan.userId;
                  let ActiveStat =
                    _id?.isActivate && !_id?.isExpriy
                      ? "Active"
                      : _id?.isActivate == false && _id?.isExpriy == false
                      ? "In Active"
                      : _id?.isActivate == false && _id?.isExpriy == true
                      ? "Expried"
                      : "";
                  if (planData._isEnable || ActiveStat == "Active") {
                    return (
                      <>
                        {ActiveStat == "In Active" ||
                        ActiveStat == "Expried" ? (
                          <Card
                            className={
                              _id?.isExpriy
                                ? "card_custom_sec disabled_card"
                                : "card_custom_sec"
                            }>
                            <div
                              className={
                                ActiveStat == "Active"
                                  ? "ribbon activeborder ribbon-top-left"
                                  : ActiveStat == "In Active"
                                  ? "ribbon inactiveborder ribbon-top-left"
                                  : ActiveStat == "Expried"
                                  ? "ribbon expiryborder ribbon-top-left"
                                  : ""
                              }>
                              <span
                                className={
                                  ActiveStat == "Active"
                                    ? "activeclr"
                                    : ActiveStat == "In Active"
                                    ? "inactiveclr"
                                    : ActiveStat == "Expried"
                                    ? "expiryclr"
                                    : ""
                                }>
                                {ActiveStat}
                              </span>
                            </div>

                            <Card.Body className="p-0">
                              <div className="card-body-content">
                                <div className="p-4 inside-card">
                                  <div>
                                    <div className="d-flex justify-content-center">
                                      {planData?.image ? (
                                        <img
                                          src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                          alt="bigbot"
                                          className="img-fluid big-bot"
                                        />
                                      ) : (
                                        <img
                                          src={BogBot}
                                          alt="bigbot"
                                          className="img-fluid big-bot"
                                        />
                                      )}
                                    </div>
                                    {/* <div className="d-flex flex-column align-items-center justify-content-center">
                                      <p className="amount mb-2">
                                        ${" "}
                                        {parseFloat(planData?._amount) /
                                          10 ** 18}
                                      </p>
                                      <p className="trade mb-2">
                                        (trade with ${" "}
                                        {parseFloat(planData?._minIvestment) /
                                          10 ** 18}
                                        -
                                        {parseFloat(planData?._maxIvestment) /
                                          10 ** 18}
                                        )
                                      </p>
                                    </div> */}
                                  </div>
                                </div>
                                {/* {_id.isActivate || _id.isExpriy ? (
                                  ""
                                ) : ( */}
                                  <div className="">
                                    <Button
                                      className="d-block common_green_Btn  w-100 activate-bot"
                                      onClick={() => {
                                        // let plandata = {
                                        //   planId: item._planID,
                                        //   _id: _id._id,
                                        //   userId: userId,
                                        // };
                                        // UserBotSubscribe(plandata);
                                        handleReedemShow();
                                        setPlanId(item._planID);
                                        setUserId(userId);
                                        setplanid(_id._id);
                                      }}>
                                      Redeemm
                                    </Button>
                                  </div>
                                 {/* )} */}
                              </div>
                            </Card.Body>
                          </Card>
                        ) : (
                          <Card
                            className={
                              _id?.isExpriy
                                ? "card_custom_sec disabled_card"
                                : "card_custom_sec"
                            }>
                            <div
                              className={
                                ActiveStat == "Active"
                                  ? "ribbon activeborder ribbon-top-left"
                                  : ActiveStat == "In Active"
                                  ? "ribbon inactiveborder ribbon-top-left"
                                  : ActiveStat == "Expried"
                                  ? "ribbon expiryborder ribbon-top-left"
                                  : ""
                              }>
                              <span
                                className={
                                  ActiveStat == "Active"
                                    ? "activeclr"
                                    : ActiveStat == "Active"
                                    ? "inactiveclr"
                                    : ActiveStat == "Expried"
                                    ? "expiryclr"
                                    : ""
                                }>
                                Active
                              </span>
                            </div>

                            <Card.Body className="p-0 redeem-modal common-modal">
                              <div className="card-body-content">
                                <div className="p-4 inside-card">
                                  <div>
                                    <div className="d-flex justify-content-center">
                                      {planData?.image ? (
                                        <img
                                          src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                          alt="bigbot"
                                          className="img-fluid big-bot"
                                        />
                                      ) : (
                                        <img
                                          src={BogBot}
                                          alt="bigbot"
                                          className="img-fluid big-bot"
                                        />
                                      )}
                                    </div>

                                    <div className="">
                                      <div className="redeem-details">
                                        <div className="row">
                                          <div className="col-12 mb-2">
                                            <p className="mb-0 txt-header">
                                              Plan Name
                                            </p>
                                            <p className="mb-0 txt-val">
                                              {planData?.name}
                                            </p>
                                          </div>

                                          {/* <div className="col-12 mb-2">
                                            <p className="mb-0 txt-header">
                                              Trading Capital
                                            </p>
                                            <p className="mb-0 txt-val">
                                              {userFuel.tradeAmount} - BNB
                                            </p>
                                          </div>
                                          <div className="col-12 mb-2">
                                            <p className="mb-0 txt-header">
                                              Total Profit
                                            </p>

                                            {userProfit &&
                                              userProfit?.map(
                                                (userProfititem, i) => {
                                                  return (
                                                    <>
                                                      {userFuel.tradeAmount ==
                                                      0 ? (
                                                        0
                                                      ) : (
                                                        <p className="mb-0 txt-val">
                                                          {
                                                            userProfititem.profits
                                                          }{" "}
                                                          - {userProfititem._id}
                                                        </p>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </div>

                                          <div className="col-12">
                                            <p className="mb-2 txt-header">
                                              Today Profit
                                            </p>

                                            {todayProfit &&
                                              todayProfit?.map(
                                                (todayProfititem, i) => {
                                                  return (
                                                    <>
                                                      {userFuel.tradeAmount ==
                                                      0 ? (
                                                        0
                                                      ) : (
                                                        <p className="mb-0 txt-val">
                                                          {
                                                            todayProfititem.profits
                                                          }{" "}
                                                          -{" "}
                                                          {todayProfititem._id}
                                                        </p>
                                                      )}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </div> */}

                                        </div>

                                        {/* <div className="d-flex justify-content-between mt-4">
                                          <Button
                                            type="button"
                                            size="md"
                                            className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                                            onClick={() => {
                                              userProfitDetails(userId);
                                              setUserId(userId);
                                              handleClaimShow();
                                            }}>
                                            Claim
                                          </Button>
                                          <Button
                                            type="button"
                                            size="md"
                                            className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                                            onClick={handleDepositShow}>
                                            Deposit
                                          </Button>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  {/* <Button
                                    className="d-block common_green_Btn  w-100 activate-bot"
                                    onClick={() => {
                                      planUpgrade(_id._id);
                                    }}>
                                    Upgradee
                                  </Button> */}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                      </>
                    );
                  }
                })
              ) : (
                <OwlCarousel
                  id="customer-testimonoals"
                  className="owl-carousel owl-theme dash-custom-owl"
                  {...options}>
                  {userPlans &&
                    userPlans?.map((item, i) => {
                      let isAnyActive = Contractplans?.find(
                        (val) => val.disable == true
                      );

                      var planData = isEmpty(Contractplans)
                        ? {}
                        : Contractplans[item._planID];
                      let DBPlan = plans?.find(
                        (val) => val.planId == item._planID
                      );

                      planData = { ...planData, ...DBPlan };
                      let userFuel = calculateFuel(
                        planData.maxLimit,
                        planData.maxDeposit
                      );
                      let _id = userPlan?.purchaseDetails?.find(
                        (val) => val?.planId == planData?._id
                      );
                      let isActivePlan = userPlan?.purchaseDetails?.find(
                        (val) => val?.isActivate == true
                      );

                      planData = { ...planData, ...DBPlan };
                      console.log(planData, "planData");

                      let userId = userPlan.userId;
                      let ActiveStat =
                        _id?.isActivate && !_id?.isExpriy
                          ? "Active"
                          : _id?.isActivate == false && _id?.isExpriy == false
                          ? "In Active"
                          : _id?.isActivate == false && _id?.isExpriy == true
                          ? "Expried"
                          : "";
                      if (planData._isEnable || ActiveStat == "Active") {
                        return (
                          <>
                            {ActiveStat == "In Active" ||
                            ActiveStat == "Expried" ? (
                              <Card
                                className={
                                  _id?.isExpriy
                                    ? "card_custom_sec disabled_card"
                                    : "card_custom_sec"
                                }>
                                <div
                                  className={
                                    ActiveStat == "Active"
                                      ? "ribbon activeborder ribbon-top-left"
                                      : ActiveStat == "In Active"
                                      ? "ribbon inactiveborder ribbon-top-left"
                                      : ActiveStat == "Expried"
                                      ? "ribbon expiryborder ribbon-top-left"
                                      : ""
                                  }>
                                  <span
                                    className={
                                      ActiveStat == "Active"
                                        ? "activeclr"
                                        : ActiveStat == "In Active"
                                        ? "inactiveclr"
                                        : ActiveStat == "Expried"
                                        ? "expiryclr"
                                        : ""
                                    }>
                                    {ActiveStat}
                                  </span>
                                </div>

                                <Card.Body className="p-0">
                                  <div className="card-body-content">
                                    <div className="p-4 inside-card">
                                      <div>
                                        <div className="d-flex justify-content-center">
                                          {planData?.image ? (
                                            <img
                                              src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                              alt="bigbot"
                                              className="img-fluid big-bot"
                                            />
                                          ) : (
                                            <img
                                              src={BogBot}
                                              alt="bigbot"
                                              className="img-fluid big-bot"
                                            />
                                          )}
                                        </div>
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                          <p className="amount mb-2">
                                            ${" "}
                                            {parseFloat(planData?._amount) /
                                              10 ** 18}
                                          </p>
                                          <p className="trade mb-2">
                                            (trade with ${" "}
                                            {parseFloat(
                                              planData?._minIvestment
                                            ) /
                                              10 ** 18}
                                            -
                                            {parseFloat(
                                              planData?._maxIvestment
                                            ) /
                                              10 ** 18}
                                            )
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* {_id?.isActivate || _id?.isExpriy ? (
                                      ""
                                    ) :  */}

                                    {/* {
                                    isEmpty(isActivePlan) ? ( */}

                                    <div className="">
                                      <Button
                                        className="d-block common_green_Btn  w-100 activate-bot"
                                        onClick={() => {
                                          // let plandata = {
                                          //   planId: item._planID,
                                          //   _id: _id._id,
                                          //   userId: userId,
                                          // };
                                          // UserBotSubscribe(plandata);
                                          handleReedemShow();
                                          setPlanId(item._planID);
                                          setUserId(userId);
                                          setplanid(_id._id);
                                        }}>
                                        Redeem
                                      </Button>
                                    </div>

                                    {/* ) : (
                                      ""
                                    )
                                    } */}
                                  </div>
                                </Card.Body>
                              </Card>
                            ) : (
                              <Card
                                className={
                                  _id?.isExpriy
                                    ? "card_custom_sec disabled_card"
                                    : "card_custom_sec"
                                }>
                                <div
                                  className={
                                    ActiveStat == "Active"
                                      ? "ribbon activeborder ribbon-top-left"
                                      : ActiveStat == "In Active"
                                      ? "ribbon inactiveborder ribbon-top-left"
                                      : ActiveStat == "Expried"
                                      ? "ribbon expiryborder ribbon-top-left"
                                      : ""
                                  }>
                                  <span
                                    className={
                                      ActiveStat == "Active"
                                        ? "activeclr"
                                        : ActiveStat == "Active"
                                        ? "inactiveclr"
                                        : ActiveStat == "Expried"
                                        ? "expiryclr"
                                        : ""
                                    }>
                                    Active
                                  </span>
                                </div>

                                <Card.Body className="p-0 redeem-modal common-modal">
                                  <div className="card-body-content">
                                    <div className="p-4 inside-card">
                                      <div>
                                        <div className="d-flex justify-content-center">
                                          {planData?.image ? (
                                            <img
                                              src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                              alt="bigbot"
                                              className="img-fluid big-bot"
                                            />
                                          ) : (
                                            <img
                                              src={BogBot}
                                              alt="bigbot"
                                              className="img-fluid big-bot"
                                            />
                                          )}
                                        </div>

                                        <div className="">
                                          <div className="redeem-details">
                                            <div className="row">
                                              <div className="col-12 mb-2">
                                                <p className="mb-0 txt-header">
                                                  Plan Name
                                                </p>
                                                <p className="mb-0 txt-val">
                                                  {planData?.name}
                                                </p>
                                              </div>
                                              {/* <div className="col-12 mb-2">
                                                <p className="mb-0 txt-header">
                                                  Plan SubscriptionFee
                                                </p>
                                                <p className="mb-0 txt-val">
                                                 
                                                  {planData?.subscriptionFee}
                                                  
                                                </p>
                                              </div> */}

                                              {/* <div className="col-12 mb-2">
                                                <p className="mb-0 txt-header">
                                                  Trading Capital
                                                </p>
                                                <p className="mb-0 txt-val">
                                                  {userFuel.tradeAmount} - BNB
                                                </p>
                                              </div>
                                              <div className="col-12 mb-2">
                                                <p className="mb-0 txt-header">
                                                  Total Profit
                                                </p>

                                                {userProfit &&
                                                  userProfit?.map((item, i) => {
                                                    return (
                                                      <>
                                                        <p className="mb-0 txt-val">
                                                          {item.profits} -{" "}
                                                          {item._id}
                                                        </p>
                                                      </>
                                                    );
                                                  })}
                                              </div>
                                              <div className="col-12">
                                                <p className="mb-2 txt-header">
                                                  Today Profit
                                                </p>

                                                {todayProfit &&
                                                  todayProfit?.map(
                                                    (item, i) => {
                                                      return (
                                                        <>
                                                          <p className="mb-0 txt-val">
                                                            {item.profits} -{" "}
                                                            {item._id}
                                                          </p>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </div> */}
                                            </div>

                                            {/* <div className="d-flex justify-content-between mt-4">
                                              <Button
                                                type="button"
                                                size="md"
                                                className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                                                onClick={() => {
                                                  userProfitDetails(userId);
                                                  handleClaimShow();
                                                }}>
                                                Claim
                                              </Button>
                                              <Button
                                                type="button"
                                                size="md"
                                                className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                                                onClick={handleDepositShow}>
                                                Deposit
                                              </Button>
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      {/* <Button
                                        className="d-block common_green_Btn  w-100 activate-bot"
                                        onClick={() => planUpgrade(_id._id)}>
                                        Upgrade
                                      </Button> */}
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                          </>
                        );
                      }
                    })}
                </OwlCarousel>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/*Plans List */}

      {/* <div className="row">
        <p>Your Plans</p>
        {userPlans && userPlans?.length > 0
          ? userPlans?.map((item) => {
              var planData = isEmpty(Contractplans)
                ? {}
                : Contractplans[item._planID];
              let DBPlan = plans?.find((val) => val.planId == item._planID);
              planData = { ...planData, ...DBPlan };
              if (planData._isEnable) {
                return (
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-6 col-12 purchase-bot pt-0 mt-3 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5">
                      <Card className="card_custom_sec">
                        <Card.Body className="p-0">
                          <div className="p-4">
                          
                            <div className="d-flex justify-content-center">
                              {planData?.image ? (
                                <img
                                  src={`${key?.BACK_URL}/public/plan/${planData?.image}`}
                                  alt="bigbot"
                                  className="img-fluid big-bot"
                                />
                              ) : (
                                <img
                                  src={BogBot}
                                  alt="bigbot"
                                  className="img-fluid big-bot"
                                />
                              )}
                        
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <p className="amount mb-2">
                                $ {parseFloat(planData?._amount) / 10 ** 18}
                              </p>
                              <p className="trade mb-2">
                                (trade with ${" "}
                                {parseFloat(planData?._minIvestment) / 10 ** 18}
                                -
                                {parseFloat(planData?._maxIvestment) / 10 ** 18}
                                )
                              </p>
                            </div>
                          </div>
                          <div className="">
                            <Button className="d-block common_green_Btn  w-100 activate-bot">
                              {planData.name}
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                );
              }
            })
          : ""}
      </div> */}

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="dashboard_profile_modal">
        <Modal.Header closeButton className="custom_header_border">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {/* <Card className="user_profile_card"> */}
          <Card className="modal_popup_card">
            <Card.Body>
              <div className="user_profile">
                <span className="position-relative">
                  <img
                    className="img-fluid"
                    height="100"
                    width="100"
                    src={
                      formValue.profilepic == ""
                        ? UserLogo
                        : fileObjectUrl(formValue.profilepic)
                    }
                  />
                  {/* {typeof file == "object" ? <img className="img-fluid" height="100" width="100" src={URL.createObjectURL(file)} /> : <img className="img-fluid" height="100" width="100" src={`${key.BACK_URL}/public/user/${profilelist?.profilepic}`} alt="ggg" />} */}
                </span>
              </div>
              <div class="col-md-12 mb-3 upload-btn-wrapper mt-3">
                <button class="btn">Upload Profile</button>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={(event) => onChangeValue(event)}
                />
                <p className="validErrors_style" id="upload-validErrors">
                  {validErrors && validErrors.file}
                </p>
              </div>

              <div className="user_details mb-3">
                <div className="row">
                  <div className="col-12 d-flex align-items-center">
                    <p className="mb-0">User Name</p>
                  </div>

                  <div className="col-12 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      value={formValue?.name}
                      onChange={(e) => handlechange(e)}
                    />
                  </div>
                </div>
                <p className="validErrors_style" id="upload-name">
                  {validErrors && validErrors.name}
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  size="md"
                  className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                  // onClick={handleClose}
                  onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </div>
            </Card.Body>
          </Card>
          {/* 
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> 
        </Modal.Footer> */}
      </Modal>
      {/* start of edit email modal */}

      <Modal
        show={editEmail}
        onHide={handleEditEmailClose}
        centered
        className="dashboard_profile_modal">
        <Modal.Header closeButton className="custom_header_border">
          <Modal.Title>Change Your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="modal_popup_card">
            <Card.Body>
              <div className="user_details mb-3">
                <div className="row">
                  <div className="col-12 col-sm-4 d-flex align-items-center ">
                    <p className="mb-0">New Email :</p>
                  </div>

                  <div className="col-12 col-sm-12 mt-3 mt-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      name="newEmail"
                      id="newEmail"
                      value={formValue?.newEmail}
                      placeholder="Enter your new email"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <span className="validErrors_style" id="upload-name">
                  {validErrors && validErrors.newEmail}
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  type="button"
                  size="md"
                  className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                  // onClick={handleClose}
                  onClick={changemailSubmit}>
                  Change Email
                </Button>
              </div>
            </Card.Body>
          </Card>
          {/* 
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> 
        </Modal.Footer> */}
      </Modal>

      {/* end of edit email modal */}

      <Modal
        show={redeemShow}
        onHide={handleRedeemClose}
        centered
        className="dashboard_profile_modal redeem-modal common-modal">
        <Modal.Header closeButton className="custom_header_border">
          <Modal.Title>Redeem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="redeem-details">
            <div className="row">
              <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Plan Nameoo</p>
                <p className="mb-0 txt-val">xxxx</p>
              </div>
              <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Trading Capital</p>
                <p className="mb-0 txt-val">$00</p>
              </div>
              <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Balance</p>
                <p className="mb-0 txt-val">$0</p>
              </div>
              <div className="col-12">
                <p className="mb-2 txt-header">Fuel</p>
                <div className="redeem-progress">
                  <ProgressBar now={now} label={`${now}%`} />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <Button
                type="button"
                size="md"
                className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                onClick={handleRedeemClose}>
                Deposit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Deposit Modal */}
      <Modal
        show={depositShow}
        onHide={handleDepositClose}
        centered
        className="deposit-modal common-modal">
        <Modal.Header closeButton className="custom_header_border">
          <Modal.Title>Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="deposit-details">
            <div className="row">
              <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Deposit Balance</p>
                <div className="deposit-balance mt-2">
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                  <span className="txt-val ms-2">Max</span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <Button
                type="button"
                size="md"
                className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                // handleDepositClose
                onClick={() => {
                  let data = {
                    account: walletData.walletAddress,
                    amount: amount,
                  };
                  userRecharge(data);
                }}>
                Deposit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Claim Modal */}
      <Modal
        show={claimShow}
        onHide={handleClaimClose}
        // size="sm"
        centered
        className="claim-modal common-modal">
        <Modal.Header closeButton className="custom_header_border">
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="claim-details">
            <div className="row">
              {/* <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Total Profit</p>
                <p className="mb-0 txt-val">000</p>
              </div> */}
              <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Your Share</p>
                {userProfit &&
                  userProfit?.map((item, i) => {
                    return (
                      <>
                        <p className="mb-0 txt-val">{item._id}</p>
                        <p className="mb-0 txt-val">{item.profits}</p>
                        <Button
                          type="button"
                          size="md"
                          className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                          onClick={() => {
                            let data = {
                              userId: userID,
                              token: item._id,
                              amount: item.profits,
                            };
                            userClaim(data);
                            handleClaimClose();
                          }}>
                          Claim
                        </Button>
                      </>
                    );
                  })}
              </div>
            </div>

            {/* <div className="d-flex justify-content-center mt-2">
              <Button
                type="button"
                size="md"
                className="btn common_green_Btn sign-up-btn btn-block px-3 py-2"
                onClick={handleClaimClose}>
                Claim
              </Button>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* Redeem Modal */}
      <Modal
        show={reedemShow}
        onHide={handleReedemClose}
        // size="sm"
        centered
        className="claim-modal common-modal">
        <Modal.Header closeButton className="custom_header_border">
          <Modal.Title>Reedem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="claim-details">
            <div className="row">
              <div className="col-12 mb-2">
                <p className="mb-0 txt-header">Choose Pool</p>
                <Select
                  value={
                    poolId != "" ? pools.find((val) => val.value == poolId) : ""
                  }
                  onChange={(e) => {
                    selectChange(e);
                  }}
                  options={pools}
                  className="border_blue_select basic-multi-select"
                />

                <Button
                  className="d-block common_green_Btn  w-100 activate-bot"
                  onClick={() => {
                    UserBotSubscribe(planId, poolId, planid);
                  }}>
                  {/* {planData?.name} */}
                  Redeem
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DashboardContent;
