import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { IoMdNotifications } from "react-icons/io";
import { IconName } from "react-icons/io5";
import { BiSolidUser } from "react-icons/bi";
import DashboardSidebar from "./DashboardSidebar";
import DashboardHeader from "./DashboardHeader";
import DashboardContent from "./DashboardContent";

const Dashboard =() => {
  const [sidebar, setSidebar] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);

  const handleClose = () => {
    setSidebar(!sidebar);
  };

  const updateDimensions = () => {
    // setWidth(window.innerWidth);
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
  };

  useEffect(() => {
   
    if (window.innerWidth < 767) {
      setSidebar(false);
    } else if (window.innerWidth > 767) {
      setSidebar(true);
    }
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    // window.location.reload();
    // window.location.reload(true);
  }, []);
  // const [reloadPage, setReloadPage] = useState(true);

  // useEffect(() => {
  //   if (reloadPage) {
  //     window.location.reload();
  //   }
  // }, [reloadPage]);

  return (
    <div className="dashboard dash_board">
      <div className="container-fluid d-flex ps-0 ">
        <div className="left_side_sec">
          <DashboardSidebar handleClose={()=>handleClose()} sidebar={sidebar} />
        </div>
        <div className="right_side_sec px-4 py-4">
          <DashboardHeader handleClose={()=>handleClose()} />
          <DashboardContent />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
