import React, { useEffect } from "react";
import logo from "../Assets/Img/bot_img.png";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

function DashboardSidebar({ sidebar, handleClose }) {
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("navbar_false");
  }, []);

  // const handleNavigate = () => {
  //   window.location.href = "/";
  // };

  return (
    <>
      <div className={sidebar ? "sidebar_sec" : "d-none "}>
        <div className="d-flex justify-content-end pt-4 pe-3 side_bar_close_btn">
          <span onClick={handleClose}>
            {" "}
            <AiOutlineClose />
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="bot_logo"
              // onClick={handleNavigate}
            />
          </Link>
        </div>
        <div className="mt-4">
          <Link to="/" className="text-decoration-none">
            <p className="home_text ps-4 mb-4 mb-0 d-flex align-items-center">
              Home
            </p>
          </Link>
          <Link to="/dashboard" className="text-decoration-none">
            <p className="dashboard_text ps-4 mb-4 mb-0 d-flex align-items-center">
              Dashboard
            </p>
          </Link>
          <Link to="/launchpad" className="text-decoration-none">
            <p className="launchpad_text ps-4 mb-4 mb-0 d-flex align-items-center">
              Launchpad
            </p>
          </Link>
          <Link to="/locked-staking" className="text-decoration-none">
            <p className="lockedstake_text ps-4 mb-4 mb-0 d-flex align-items-center">
              Locked Staking
            </p>
          </Link>

          {/* <Link to="/wallet" className="text-decoration-none">
            <p className="wallet_text ps-4 mb-4 mb-0 d-flex align-items-center">
              Wallet
            </p>
          </Link> */}
          {/* <Link to="/purchase-history" className="text-decoration-none">
            <p className="trade_history_text ps-4 mb-4 d-flex align-items-center">
              Purchase History
            </p>
          </Link> */}
          <Link to="/notification" className="text-decoration-none">
            <p className="notification_text ps-4 mb-4 d-flex align-items-center">
              Notification
            </p>
          </Link>
          {/* <Link to="/purchase-bot" className="text-decoration-none">
            <p className="plan_txt ps-4 mb-4 d-flex align-items-center">Plan</p>
          </Link> */}
          {/* <Link to="/security" className="text-decoration-none">
            <p className="security_txt ps-4 mb-4 d-flex align-items-center">
              Security
            </p>
          </Link> */}

          {/* <Link to="/affiliateprogram" className="text-decoration-none">
            <p className="affiliateprogram_text ps-4 mb-4 d-flex align-items-center">
              Affiliate Program
            </p>
          </Link> */}
          {/* <Link to="/statistics" className="text-decoration-none">
            <p className="statistics_text ps-4 mb-0 d-flex align-items-center">
              Statistics
            </p>
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default DashboardSidebar;
