import React, { useState, useEffect } from "react";
import { IoMdNotifications } from "react-icons/io";
import { BiSolidUser } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useHistory } from "react-router-dom";
import hamburger from "../Assets/btn-icon.png";
// import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
// import { useHistory } from 'react-router';
//import action
import { logOut } from "../actions/commonAction";
import { readNotification } from "../api/api";
import { useDispatch, useSelector } from "react-redux";

import { timeAge } from "../lib/dateTimeHelper";

function DashboardHeader({ handleClose }) {
  // let navigate =useNavigate()
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const SingOut = () => {
    try {
      logOut(dispatch);
      history.push("/");
    } catch (err) {
      console.log(err, "SingOut__err");
    }
  };
  const readAllMsg = async () => {
    let { result, message } = await readNotification();

    setData(result)
    console.log("result----", result);
    // noticePopup(dispatch, false);
  };
  const { noticeData } = useSelector((state) => state.user);
  console.log("noticeData", noticeData);


  const notificationData = [
    {
      id: 1,
      value: 9,
      time: "mins",
      notificaionMessage: "Login Successfully",
    },
    {
      id: 2,
      value: 8,
      time: "hrs",
      notificaionMessage: "Login Failed",
    },
    {
      id: 3,
      value: 10,
      time: "day",
      notificaionMessage: "Login Successfully",
    },
    {
      id: 4,
      value: 2,
      time: "mon",
      notificaionMessage: "Login Failed",
    },
  ];
  return (
    <div className="dashboard_header px-2 py-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="hamburger_icon" onClick={() => { handleClose() }}>
          {/* <GiHamburgerMenu size={20} /> */}
          {/* <img src={hamburger} alt="hamburger" /> */}
          <span className="dash_custom_hamburger">
            <GiHamburgerMenu />
          </span>
        </div>
        <div className="d-flex align-items-center">
          <Dropdown className="">
            <Dropdown.Toggle className="common_green_Btn notification not_count position-relative">
              <div className="not_count_wrap position-absolute">
                {noticeData && noticeData.length ? noticeData.length : 0}
              </div>
              <IoMdNotifications className="" size={18} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="noti_cus_drop p-2">
              <h6>Notification</h6>

              {/* start of old notification ui */}
              {/* <div className="text-end px-2">
                {noticeData?.length > 0 ?
                <a href="javascript:void(0)" className="notification_link_italic" onClick={(e)=>{readAllMsg()}}>Mark all as read</a>
                :
                <></>
}
                </div>
              {noticeData?.length > 0 && noticeData ? noticeData?.map((val, i) => {
                  console.log("valvalval", val);
                  return (
                    <div className="notification_inside_content mt-2">
                      <div className="d-flex mb-3 justify-content-between align-items-center">
                        <div className="user_img">
                          <BiSolidUser size={16} />
                        </div>
                        <p className="ps-2 mb-0">{val.description}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="notification_inside_content mt-4">
                  <p className="text-center">
                    You don't have any notifications.
                  </p>
                </div>
              )}
              {noticeData?.length > 0 ? (
                <div className="text-center mt-2">
            <Link to="/notification" className="notification_link_italic">Show All Notifications</Link>
            </div>
            :
            <></>} */}

              {/* end of old notification ui */}

              {/* new notification ui */}


              <div>
                {noticeData && noticeData.length > 0 ?
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      onClick={(e) => {
                        readAllMsg();
                      }}
                      className="not_rd_btn"
                    >
                      Mark all as read
                    </button>
                  </div>
                  : ""}
                {noticeData && noticeData.length > 0 ? noticeData.map((value) => (
                  <div className="not_msg_wrap mt-3">
                    <div id={value.id} className="new_not_value py-2">
                      <p className="mb-0 not_time_txt">
                        {timeAge(value.createdAt)} ago
                      </p>
                      <p className="not_msg_txt mb-0">
                        {value.description}
                      </p>
                    </div>

                  </div>
                )) : <div className="notification_inside_content mt-4">
                  <p className="text-center">You don't have any notifications.</p>
                </div>}
                <div className="mt-3 d-flex justify-content-center">
                  <button className="not_shwall_btn" onClick={() => { history.push('/notification') }}>
                    Show All Notification
                  </button>
                </div>
              </div>




              {/* <div className="d-flex mb-3 justify-content-between align-items-center">
                  <div className="user_img">
                    <BiSolidUser size={16}/>
                  </div>
                  <p className="ps-2 mb-0">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s.
                  </p>
                </div>
                <div className="d-flex mb-3 justify-content-between align-items-center">
                  <div className="user_img">
                    <BiSolidUser size={16}/>
                  </div>
                  <p className="ps-2 mb-0">
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s.
                  </p>
                </div> */}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="ps-3">
            <Dropdown.Toggle className="common_green_Btn user_Logo">
              <BiSolidUser className="" size={18} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadown_drop_menu">
              <Dropdown.Item href="javascript:void(0)"
                onClick={() => { SingOut() }}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
