import React, { useEffect, useState } from "react";
import { Button, Modal, InputGroup, Form } from "react-bootstrap";
// import { getAccount } from "../../Hooks/useAccounts";
// import { UnStake } from "../../Hooks/useStake";

const WithdrawModal = (props) => {
  const [Withdraw, setWithdraw] = useState(true);
  const [withdrawAmount, setwithdrawAmount] = useState("0");
  const [accountInfo, setAccountInfo] = useState("");
  const [isMax, setIsMax] = useState(false);
  const [Maxvalwithdraw, setmaxvalwithdraw] = useState("0");
  const [Maxwithdraw, setmaxwithdraw] = useState("0");
  const [pendingTx, setpendingTx] = useState(false);
  const [unstakedate, setuserstatedate] = useState("-");

  const maxwithdraw = () => {
    if (parseFloat(props.userStakedAmount) > 0) {
      setmaxwithdraw(props.userStakedAmount);
    }
  };

  const UseWithdraw = async () => {
    const pid = props.pid;
    const decimal = props.data.decimals;
    const amt = isMax ? Maxwithdraw : withdrawAmount;
    // await UnStake(accountInfo, pid, amt, decimal, isMax).then(() => {});
  };

  useEffect(() => {
    // setAccountInfo(getAccount());
    setAccountInfo();
    setmaxvalwithdraw(props?.userStakedAmount / 10 ** props?.data?.decimals);
  }, []);

  return (
    <>
      <Modal show={Withdraw} centered size="md text-black">
        <Modal.Header className="pb-0">
          <h5 className="">Withdraw {props?.data?.symbol} Tokens</h5>
          <button
            className="btn btn-link themeclr close"
            onClick={() => props.onDismiss()}
          >
            <span className="fa fa-times"></span>
          </button>
        </Modal.Header>
        <Modal.Body className="py-4">
          <p className="text-right themeclr">
            Unstake available after :{" "}
            {props?.unstakeDate ? props?.unstakeDate : unstakedate}
          </p>
          <p className="text-end themeclr">
            {Maxvalwithdraw && Maxvalwithdraw} {props?.data?.symbol} Available
          </p>
          <InputGroup className="mb-3 h-75">
            <Form.Control
              aria-label="Amount (to the nearest dollar)"
              value={
                isMax
                  ? Maxwithdraw / 10 ** props?.data?.decimals
                  : withdrawAmount
              }
              onChange={(e) => {
                setwithdrawAmount(e.target.value);
                setIsMax(false);
              }}
              placeholder="0"
            />
            <InputGroup.Text className="themeclr">
              {" "}
              {props?.data?.symbol}
            </InputGroup.Text>
            <InputGroup.Text>
              <button
                className="chocobtn"
                onClick={() => {
                  setIsMax(true);
                  maxwithdraw();
                }}
              >
                MAX
              </button>
            </InputGroup.Text>
          </InputGroup>
          <div className="d-flex justify-content-around mb-4 mt-5">
            <button
              className="btn getstarted yellowBtn"
              onClick={() => setWithdraw(false)}
            >
              Cancel
            </button>
            <button
              className="btn getstarted green-btn"
              onClick={() => UseWithdraw()}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawModal;
