import { combineReducers } from "redux";
import account from "./accounts";
import wallet from "./walletReducer";
import plan from "./plan";
import currency from "./currencyReducer";
import compound from "./compound";
import chart from "./chartData"

export default combineReducers({
  user: account,
  wallet: wallet,
  plan: plan,
  currency: currency,
  compound: compound,
  chart:chart
});
