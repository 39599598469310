import { BuyContractABI } from "../config/BuyContractABI";
import { BUYCONTRACTADDRESS } from "../config/config";
import { UseWeb3, useWeb3 } from "./useWeb3";

import { getWalletAddress } from "../lib/localStorage";
import isEmpty from "../lib/isEmpty";
import { pid } from "process";
import { COMPOUND_DATA } from "../constant";
import { PRESALEABI } from "../config/config";
import { ERC20_ABI } from "../config/Erc20";
import { GetChainIndex } from "./useWeb3";
import { CHAINS } from "../config/config";
import toast, { Toaster } from "react-hot-toast";
import { style, iconTheme, position } from "./useToast";
import BigNumber from "bignumber.js";
import { toastAlert } from "../lib/toastAlert";

export const UseBuyContract = async () => {
  const web3 = await useWeb3();
  try {
    const contract = new web3.eth.Contract(BuyContractABI, BUYCONTRACTADDRESS);
    console.log(contract, "contract");
    return contract;
  } catch (err) {
    console.log(err, "useBuyContract__err");
  }
};

export const BuyBot = async (data) => {
  const web3 = await useWeb3();
  try {
    console.log(data, "BuyBot", web3);
    // const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    const account = getWalletAddress();
    const gasPrice = await web3.eth.getGasPrice();
    let encoded = await contract.methods
      .purchaseBot(
        data.planId.toString(),
        data.periodId.toString(),
        web3.utils.toChecksumAddress(data.tokenAddress),
        web3.utils.toChecksumAddress(data.referrer)
      )
      .encodeABI();
    let estimatedGasLimit = await web3.eth.estimateGas({
      from: web3.utils.toChecksumAddress(account),
      to: web3.utils.toChecksumAddress(BUYCONTRACTADDRESS),
      data: encoded,
    });
    const Buybot = await contract.methods
      .purchaseBot(
        data.planId.toString(),
        data.periodId.toString(),
        web3.utils.toChecksumAddress(data.tokenAddress),
        web3.utils.toChecksumAddress(data.referrer)
      )
      .send({
        from: web3.utils.toChecksumAddress(account),
        gasLimit: parseInt(estimatedGasLimit * 1.5, 10),
        gasPrice: gasPrice,
      });
    return Buybot;
  } catch (err) {
    console.log(err, "useBuyContract__err");
  }
};

export const getTotalPlans = async () => {
  try {
    const contract = await UseBuyContract();
    const plans = await contract.methods.getTotalPlan().call();
    console.log(plans, "plans");
    if (plans) {
      return plans;
    }
  } catch (err) {
    console.log(err, "getTotalPlans__err");
  }
};

export const getTotalPeriods = async (dispatch) => {
  try {
    console.log("getTotalPeriods");
    const web3 = await UseWeb3();
    const contract = new web3.eth.Contract(BuyContractABI, BUYCONTRACTADDRESS);
    console.log("getTotalPeriods", contract);
    const compoundingPeriods = await contract.methods.getTotalPeriod().call();
    console.log(compoundingPeriods, "compoundingPeriods++++++++");
    if (compoundingPeriods) {
      dispatch({
        type: COMPOUND_DATA,
        payload: compoundingPeriods,
      });

      return compoundingPeriods;
    }
  } catch (err) {
    console.log(err, "getTotalPeriods__err");
  }
};
export const getPeriods = async (pid) => {
  try {
    const contract = await UseBuyContract();
    const compoundingPeriods = await contract.methods
      .getPeriod(pid.toString())
      .call();
    if (compoundingPeriods) {
      return compoundingPeriods;
    }
  } catch (err) {
    console.log(err, "getTotalPeriods__err");
  }
};
export const getuserPlan = async (planId, walletaddress) => {
  // const web3 = await useWeb3();
  try {
    console.log(planId, walletaddress, "getuserPlan");
    const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    const account = getWalletAddress();
    const userPlan = await contract.methods
      .getuserPlan(
        web3.utils.toChecksumAddress(walletaddress),
        planId.toString()
      )
      .call({ from: walletaddress });
    console.log(userPlan, "getuserPlan");
    if (userPlan) {
      return userPlan;
    }
  } catch (err) {
    console.log(err, "getTotalPeriods__err");
  }
};

export const checkuserPlan = async (plan, walletaddress) => {
  try {
    console.log(walletaddress, plan, "checkuserPlan");
    if (plan.length > 0) {
      for (let i = 0; i < plan.length; i++) {
        let userPlan = await getuserPlan(i, walletaddress);
        console.log(
          parseFloat(userPlan._buyTime) > 0 && plan[i]._isEnable,
          "checkuserPlan"
        );
        if (parseFloat(userPlan._buyTime) > 0 && plan[i]._isEnable) {
          return true;
        }
        if (plan.length - 1 && userPlan._buyTime < 0) {
          return false;
        }
      }
    }
  } catch (err) {
    console.log(err, "checkuserPlan_err");
  }
};

export const getAlluserPlan = async (plan, walletaddress) => {
  try {
    console.log(walletaddress, plan, "checkuserPlan");
    const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    if (plan.length > 0) {
      for (let i = 0; i < plan.length; i++) {
        let userPlan = await getuserPlan(i, walletaddress);
        let refferAddress = await contract.methods
          .parentOf(web3.utils.toChecksumAddress(walletaddress))
          .call();
        console.log(userPlan._buyTime, "checkuserPlan");
        if (parseFloat(userPlan._buyTime) > 0) {
          return userPlan;
        }
        // if(plan.length-1 && userPlan._buyTime<0){
        //     return false
        // }
      }
    }
  } catch (err) {
    console.log(err, "checkuserPlan_err");
  }
};
export const getAffilateFee = async () => {
  try {
    const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    const account = getWalletAddress();
    const fee = await contract.methods.getAffilateFee().call();
    return fee;
  } catch (err) {
    console.log(err, "getAffilateFee__err");
  }
};

export const getOwner = async () => {
  try {
    const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    const account = getWalletAddress();
    const owner = await contract.methods.owner().call();
    return owner;
  } catch (err) {
    console.log(err, "getAffilateFee__err");
  }
};

export const getRefferAddress = async (walletaddress) => {
  try {
    const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    let refferAddress = await contract.methods
      .parentOf(web3.utils.toChecksumAddress(walletaddress))
      .call();
    return refferAddress;
  } catch (err) {
    console.log(err, "refferAddress__err");
  }
};
export const UseMasterChef = async (VALUE) => {
  try {
    const web3 = await useWeb3();

    const contract = new web3.eth.Contract(PRESALEABI, VALUE);
    return contract;
  } catch (e) {
    console.log("error", e);
  }
};
export const UseERC20 = async (VALUE) => {
  try {
    const web3 = await useWeb3();
    // const web3 = await usehttpProvider();
    const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
    return Tokencontract;
  } catch (e) {}
};

export const sleep = (ms) => {
  new Promise((resolve) => setTimeout(resolve, ms));
};

export const toFixedNumber = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

export const BotSubscribe = async (poolID, planID) => {
  console.log("🚀 ~ BotSubscribe ~ data:", poolID);
  const web3 = await useWeb3();
  try {
    // const web3 = await UseWeb3();
    const contract = await UseBuyContract();
    const account = getWalletAddress();
    const gasPrice = await web3.eth.getGasPrice();
    let encoded = await contract.methods.Redeem(poolID, planID).encodeABI();
    let estimatedGasLimit = await web3.eth.estimateGas({
      from: web3.utils.toChecksumAddress(account),
      to: web3.utils.toChecksumAddress(BUYCONTRACTADDRESS),
      data: encoded,
    });
    const Subscribebot = await contract.methods
      .Redeem(poolID.toString(), planID.toString())
      .send({
        from: web3.utils.toChecksumAddress(account),
        gasLimit: parseInt(estimatedGasLimit * 1.5, 10),
        gasPrice: gasPrice,
      });
    return Subscribebot;
  } catch (err) {
    console.log(err, "BotSubscribe__err");
  }
};

export const UserRecharge = async (data) => {
  try {
    const web3 = await useWeb3();
    console.log(data, "data+++++++++");
    const account = getWalletAddress();
    let amount = data.amount * 10 ** 18;
    let BNBestimatedGasLimit = await web3.eth.estimateGas({
      from: account,
      to: data.account,
      value: amount,
    });
    let sendbnb = await web3.eth.sendTransaction({
      from: account,
      to: data.account,
      value: amount.toString(),
      gas: BNBestimatedGasLimit,
    });
    console.log(sendbnb, "sendbnb");
    return sendbnb;
  } catch (err) {
    console.log("userRecharge_err", err);
  }
};

export const getUSDTAddress = () => {
  try {
    const index = GetChainIndex();
    const ContractAddress = CHAINS[index].USDT;

    return ContractAddress;
  } catch (e) {}
};

export const UsegetSaleInfo = async (presaleAddress, account) => {
  try {
    const web3 = await useWeb3();
    const saleContract = await UseMasterChef(presaleAddress);
    const usdtaddress = await getUSDTAddress();
    const presaletoken = await saleContract.methods.tokenAddress().call();
    const presaletokenContract = await UseERC20(presaletoken);
    const erc20Contract = await UseERC20(usdtaddress);
    const UserusdttokenBalance = await erc20Contract.methods
      .balanceOf(account)
      .call();
    const presaletokensymbol = await presaletokenContract.methods
      .symbol()
      .call();
    const tokenpricePer = await saleContract.methods.tokenRatePerEth().call();

    const minamount =
      (await saleContract.methods.minEthLimit().call()) / 10 ** 18;
    const maxamount =
      (await saleContract.methods.maxEthLimit().call()) / 10 ** 18;
    const pricePerCrypto = await saleContract.methods
      .tokenRatePercrypto()
      .call();
    const busdminamount =
      (await saleContract.methods.BUSDminEthLimit().call()) / 10 ** 18;
    const busdmaxamount =
      (await saleContract.methods.BUSDmaxEthLimit().call()) / 10 ** 18;
    const bnbearnedcap = await saleContract.methods.earnedCap().call();
    const bnbearnedcapRoundValue = UseRoundValue(bnbearnedcap, 18);
    const busdearnedcap = await saleContract.methods.BUSDearnedCap().call();
    const busdearnedcaproundvalue = UseRoundValue(busdearnedcap, 18);
    const allowance = await erc20Contract.methods
      .allowance(account, presaleAddress)
      .call();
    const investedT =
      (await saleContract.methods.getUserInvestments(account).call()) /
      10 ** 18;
    const busdinvestedT =
      (await saleContract.methods.getUserBUSDInvestments(account).call()) /
      10 ** 18;
    const UserBNB = (await web3.eth.getBalance(account)) / 10 ** 18;

    //  const busdbalance = await erc20Token.methods.balanceOf(ADDRESS).call() / 10 ** 18;
    const claimb =
      (await saleContract.methods.getUserClaimbale(account).call()) / 10 ** 18;
    const soldT = (await saleContract.methods.soldTokens().call()) / 10 ** 18;

    const ispresaleopen = await saleContract.methods.isPresaleOpen().call();
    const isclaimable = await saleContract.methods.isClaimable().call();
    const hard = await saleContract.methods.hardCap().call();
    const bnbroundvalue = UseRoundValue(hard, 18);
    const busdhard = await saleContract.methods.BUSDhardCap().call();
    const busdhardroundvalue = UseRoundValue(busdhard, 18);
    const endD = await saleContract.methods.endTime().call();
    const date = new Date(parseInt(endD) * 1000);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const saleEndTime = date.toLocaleDateString("en-US", options);

    const data = {
      pricePer: tokenpricePer,
      minamount: minamount,
      maxamount: maxamount,
      pricePerCrypto: pricePerCrypto,
      busdminamount: busdminamount,
      busdmaxamount: busdmaxamount,
      bnbearnedcap: bnbearnedcapRoundValue.toString(),
      busdearnedcap: busdearnedcaproundvalue.toString(),
      investedT: investedT,
      busdinvestedT: busdinvestedT,
      claimb: claimb.toString(),
      soldT: soldT,
      saleEndTime: saleEndTime,
      ispresaleopen: ispresaleopen,
      hardCap: bnbroundvalue,
      busdhard: busdhardroundvalue.toString(),
      isclaimable: isclaimable,
      usdtaddress: usdtaddress,
      UserusdttokenBalance: UserusdttokenBalance,
      presaletoken: presaletoken,
      UserBNB: UserBNB,
      allowance: allowance,
      presaletokensymbol: presaletokensymbol,
    };

    return data;
  } catch (e) {
    console.log("UsegetSaleInfo", e);
  }
};

export const UseBuyToken = async (
  coinname,
  useramount,
  token,
  presaleAddress,
  account,
  isMax
) => {
  try {
    const web3 = await useWeb3();
    const erc20Contract = await UseERC20(token);
    const symbol = await erc20Contract.methods.symbol().call();
    const saleContract = await UseMasterChef(presaleAddress);
    const amount = !isMax ? toFixedNumber(useramount * 10 ** 18) : useramount;
    const data = await saleContract.methods
      .buyToken(amount.toString())
      .send({ from: account });
    console.log(data, "UseBuyToken");
    if (data.status == true) {
      toastAlert("success", "Buy Successfully");
    } else {
      toastAlert("error", " Try Again");
    }

    // await toast.promise(
    //   data,
    //   {
    //     loading: `Buying ${symbol} token...`,
    //     success: "Buy Successfully",
    //     error: "Try Again",
    //   },
    //   {
    //     position: position.position,
    //     style: style,
    //     iconTheme: iconTheme,
    //   }
    // );
  } catch (err) {
    toastAlert("error", " Try Again");
    console.log(err, "ERR ERR");
  }
};

export const approveContract = async (account, token, presaleaddress) => {
  try {
    const erc20Contract = await UseERC20(token);
    const symbol = await erc20Contract.methods.symbol().call();
    const data = await erc20Contract.methods
      .approve(
        presaleaddress,
        "115792089237316195423570985008687907853269984665640564039457584007913129639935"
      )
      .send({ from: account });

    if (data.status == true) {
      toastAlert("success", "Approved Successfully");
    } else {
      toastAlert("error", "Try Again");
    }
    // await toast.promise(
    //   data,
    //   {
    //     loading: `Approving ${symbol} token...`,
    //     success: "Approved Successfully",
    //     error: "Try Again",
    //   },
    //   {
    //     position: position.position,
    //     style: style,
    //     iconTheme: iconTheme,
    //   }
    // );
  } catch (err) {
    console.log(err, "ERR");
    toastAlert("error", "Try Again");
  }
};

export const Claim = async (account, presaleAddress) => {
  try {
    const saleContract = await UseMasterChef(presaleAddress);
    const data = await saleContract.methods
      .claimTokens()
      .send({ from: account });
    console.log(data, "ClaimClaimClaim");
    if (data.status == true) {
      toastAlert("sucess", "Claimed Successfully");
    } else {
      toastAlert("error", "Try Again");
    }
    // await toast.promise(
    //   data,
    //   {
    //     loading: "Requesting for Claim Tokens...",
    //     success: "Tokens Claimed Successfully",
    //     error: "Error ! When Claiming Token",
    //   },
    //   {
    //     position: position.position,
    //     style: style,
    //     iconTheme: iconTheme,
    //   }
    // );
  } catch (err) {
    toastAlert("error", "Try Again");
    console.log(err, "err");
  }
};

export const checkIsApproved = async (account, token, presaleAddress) => {
  try {
    const erc20Contract = await UseERC20(token);
    const allow = await erc20Contract.methods
      .allowance(account, presaleAddress)
      .call();

    return parseInt(allow) > 0 ? true : false;
  } catch (e) {}
};

export const UsergetToken = async (amount, coinname, token, presaleAddress) => {
  console.log(
    "🚀 ~ UsergetToken ~ amount, coinname, token, presaleAddress:",
    amount,
    coinname,
    token,
    presaleAddress
  );
  if (amount !== null && amount > 0 && amount !== "") {
    const saleContract = await UseMasterChef(presaleAddress);
    const erc20Contract = await UseERC20(token);
    let price = 0;
    const decimals = await erc20Contract.methods.decimals().call();

    if (coinname === "BNB") {
      const price =
        (await saleContract.methods
          .getTokensPerEth(
            new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
          )
          .call()) /
        10 ** parseInt(decimals);

      return price;
    } else {
      console.log(saleContract.methods, "saleContract");
      const price =
        (await saleContract.methods
          .getTokenPerCrypto(
            new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()
          )
          .call()) /
        10 ** parseInt(decimals);
      console.log(price, "PRICE");
      return price;
    }
  }
};

export const searchedValue = async (ProposalList, searchSymbol) => {
  const filteredData = await ProposalList.filter(
    (value) => value.isActive == searchSymbol
  );
  return filteredData;
};

export const searchedCloseProposal = async (ProposalList) => {
  const filteredData = await ProposalList.filter(
    (value) => !value.isActive === !value.status
  );
  return filteredData;
};

const UseRoundValue = (VALUE, DECIMALS) => {
  return VALUE > 0
    ? Math.round(
        new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS)).toNumber()
      )
    : new BigNumber(0);
};

export const UsegetsingleSaleInfo = async (presaleAddress, account) => {
  try {
    const web3 = await useWeb3();
    const saleContract = await UseMasterChef(presaleAddress);

    const pricePerCrypto = await saleContract.methods
      .tokenRatePercrypto()
      .call();

    const ispresaleopen = await saleContract.methods.isPresaleOpen().call();

    const data = {
      pricePerCrypto: pricePerCrypto,
      ispresaleopen: ispresaleopen,
    };

    return data;
  } catch (e) {
    console.log("UsegetSaleInfo", e);
  }
};
