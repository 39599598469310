import axios from "axios";
import { CRYPTOCOMPARE } from '../config/config'
export const priceConversion = async (currency, convertcurrency) => {
    try {
        const value = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${currency}&tsyms=${convertcurrency}&api_key=${CRYPTOCOMPARE}`);
        // console.log("priceConversion_value",value, '--', currency,convertcurrency)
        console.log("marketPriceUSD : ", value.data[`${convertcurrency}`])
        if (value.data?.[`${convertcurrency}`]) {
            console.log("marketPriceUSD : ", value.data[`${convertcurrency}`])
            return value.data[`${convertcurrency}`]
        }
        return 0
    } catch (err) {
        console.log(err, "priceConversion__err")
        return 0
    }
}

export const AverageReturn = (value, time, time1, profit,compoundingPeriod) => {
    try {
        if (time == 'Weekly' && time1 == 'Weekly') {
            let WeeklyProfit = parseFloat(profit)/4
            let Netprofit = parseFloat(value) * (parseFloat(WeeklyProfit) / 100)
            let ROI = (parseFloat(Netprofit) / parseFloat(value)) * 100
            ROI = isNaN(ROI) ? 0 : ROI
            return ROI
        }
        if (time == 'Weekly' && time1 == 'Monthly') {
            let Netprofit = parseFloat(value) * (parseFloat(profit) / 100)
            let netValue = value
            if(compoundingPeriod == 'on'){
                for(let i  = 0;i<4;i++){
                    let WeeklyProfit = parseFloat(profit)/4
                    Netprofit = parseFloat(netValue) * (parseFloat(WeeklyProfit) / 100)
                    netValue = netValue+Netprofit
                    Netprofit = Netprofit +Netprofit
                }
            }
            let ROI = (parseFloat(Netprofit) / parseFloat(netValue)) * 100
            ROI = isNaN(ROI) ? 0 : ROI
            return ROI
        }
        if (time == 'Weekly' && time1 == 'Yearly') {
            let Profitper = profit* 12
            let Netprofit = parseFloat(value) * (parseFloat(Profitper) / 100)
            let netValue = value
            if(compoundingPeriod == 'on'){
                // let loopCount = 12 * 4
                for(let i  = 0;i<52;i++){
                    let WeeklyProfit = parseFloat(profit)/4
                    Netprofit = parseFloat(netValue) * (parseFloat(WeeklyProfit) / 100)
                    netValue = netValue+Netprofit
                    Netprofit = Netprofit +Netprofit
                }
            }
            let ROI = (parseFloat(Netprofit) / parseFloat(netValue)) * 100
            ROI = isNaN(ROI) ? 0 : ROI
            return ROI
        }
        if (time == 'Monthly' && time1 == 'Monthly') {
            let Netprofit = parseFloat(value) * (parseFloat(profit) / 100)
            let ROI = (parseFloat(Netprofit) / parseFloat(value)) * 100
            ROI = isNaN(ROI) ? 0 : ROI
            return ROI
        }
        if (time == 'Monthly' && time1 == 'Yearly') {
            let Profitper = profit* 12
            let Netprofit = parseFloat(value) * (parseFloat(Profitper) / 100)
            let netValue = value
            if(compoundingPeriod == 'on'){
                for(let i  = 0;i<12;i++){
                    Netprofit = parseFloat(netValue) * (parseFloat(profit) / 100)
                    netValue = netValue+Netprofit
                    Netprofit = Netprofit +Netprofit
                }
            }
            let ROI = (parseFloat(Netprofit) / parseFloat(netValue)) * 100
            ROI = isNaN(ROI) ? 0 : ROI
            return ROI
        }
        if (time == 'Yearly' && time1 == 'Yearly') {
            let Profitper = profit * 12
            let Netprofit = parseFloat(value) * (parseFloat(Profitper) / 100)
            let ROI = (parseFloat(Netprofit) / parseFloat(value)) * 100
            ROI = isNaN(ROI) ? 0 : ROI
            return ROI
        }
    } catch (err) {
        console.log(err, "AverageReturn___err")
    }
}