import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
// import Masp from "./Modal/MaspModal";
import LaunchpadCont from "../components/LaunchpadCont";
import logoicon from "../Assets/logoicon1.png";
import logoicon1 from "../Assets/logoicon1.png";
import { getAllLaunchpads } from "../api/api";
import "./PadStacking.css";
import Header from "../Layouts/header";
const Launchpad = () => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("navbar_false");
    document.getElementsByTagName("body")[0].classList.add("navbar_true");
  }, []);
  // const [masp, setMasp] = useState(false);
  const [totalLaunchPadlength, settotalLaunchPadlength] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var { result } = await getAllLaunchpads();
    settotalLaunchPadlength(result);
  };

  const feature = useRef(null);
  const meet = useRef(null);

  const scrollToFeature = () => {
    if (feature.current) {
      feature.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const scrollToMeet = () => {
    if (meet.current) {
      meet.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header
        handleClickFeature={scrollToFeature}
        handleClickMeet={scrollToMeet}
      />

      <div className="launchpad">
        <div className="container container-theme mb-4 mt-4">
          <div className="br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox d-flex align-items-center justify-content-center">
            <div>
              {" "}
              <h1 className="innerheading">Launchpad</h1>
              <p>Buy New Tokens Before They Are Launched for Trading</p>
            </div>
          </div>
          <div className="showloader minwidth-534 text-center" id="loader_div">
            <div className="loader ">
              <img src={logoicon} className="spin" />
            </div>
          </div>
          <div className="menu_list">
            <div className="notice_link">
              {" "}
              <a
                target="_blank"
                href="https://www.labelm.io/newsdetails/63d1edb1ecf0432c7ebd9869"
                className="noticelink">
                -Notice-
              </a>
            </div>

            {/* <div className='notice_link'> <a href='/news/#latest_article' className='noticelink'>-Notice-</a></div> */}
            <div>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-3 mt-5 tab_size_small">
                <Tab eventKey="all" title="All">
                  {totalLaunchPadlength &&
                    totalLaunchPadlength?.map((item, index) =>
                      index == 0 ||
                      index == 1 ||
                      index == 2 ||
                      index == 4 ||
                      index == 5 ||
                      index == 6 ||
                      index == 7 ||
                      index == 8 ||
                      index == 9 ||
                      index == 10 ? (
                        <LaunchpadCont data={item} pid={index} />
                      ) : (
                        <></>
                      )
                    )}
                  {/* <LaunchpadCont data={''} pid={''} /> */}

                  {/* (index == 0  || index == 1 || index == 4  || index == 6 || index == 7 ? < LaunchpadCont data={item} pid={index}/> : <></>))}  */}
                </Tab>
                {/* <Tab eventKey="launch1" title="90 Days">
                    {totalLaunchPadlength && totalLaunchPadlength.map((item,index)=>(index == 0 || index == 6 ? <LaunchpadCont data={item} pid={index}/> : <></>))}
                    </Tab>
                    <Tab eventKey="launch2" title="180 Days" >
                    {totalLaunchPadlength && totalLaunchPadlength.map((item,index)=>(item.pid == 1 || item.pid == 4 ? <LaunchpadCont data={item} pid={index}/> : <></>))}
                    </Tab>
                    <Tab eventKey="launch3" title="365 Days" >
                    {totalLaunchPadlength && totalLaunchPadlength.map((item,index)=>(item.pid == 2 || item.pid == 5 ? <LaunchpadCont data={item} pid={index}/> : <></>))}
                    </Tab> */}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Launchpad;
