// import package
import io from 'socket.io-client';

// import lib
import {key} from '../config/config';
// import package
import jwt, { decode } from "jsonwebtoken";
import { toastAlert } from '../lib/toastAlert';
const socket = io(key.SOCKET_URL,{transports: ['polling']});

const createSocketUser = (userId) => {
    socket.emit('CREATEROOM', userId)
}

socket.on('userAsset', function (data) {
})
socket.on('disconnect', (reason) => {
  console.log(reason,'disconnect_reason',socket.room)
      toastAlert('error','Please reload the page')
  });
export {
    socket,
    createSocketUser
}