// import package
import jwt, { decode } from "jsonwebtoken";

// import constant
import {
    profile_Data,
    ISAUTH
} from '../constant';

// import config
import { key } from '../config/config';
import { createSocketUser } from '../config/socketConnectivity';
import { removeAuthorization } from '../config/axios'
import { removeAuthToken, setAuthToken } from '../lib/localStorage'

// import lib
import isEmpty from "../lib/isEmpty";
import { setAuthorization } from "../config/axios";

export const decodeJwt = (token, dispatch) => {
    try {
        if (!isEmpty(token)) {
            setAuthToken(token)
            setAuthorization(token)
            token = token.replace('Bearer ', '')
            const decoded = jwt.verify(token, key.secretOrKey);
            // console.log("decoded------------->",decoded);
            if (decoded) {
                createSocketUser(decoded._id)
                dispatch(setData(decoded))
                dispatch({
                    type: ISAUTH,
                    payload: true
                })
            }
        }
    } catch (err) {
        console.log(err,"decodeJwt__err")
        removeAuthToken()
        removeAuthorization()
    }
}

export const tokenVerify = (token) => {
    try {
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded = jwt.verify(token, key.secretOrKey);
            if (decoded) {
                return true
            }
        }
        return false
    } catch (err) {
        return false
    }
}

export const setData = (decoded) => {
    return {
        type: profile_Data,
        payload: decoded
    }
}