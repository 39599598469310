// import constant
import {
    CURRENCY_DATA,
    // ISAUTH
} from '../constant';

const initialValue = {
    // isAuthenticated: false,
    currencyDetails:{},
    // isAuth:false
}

//  console.log("initialValue----->>>",initialValue);
const CurrencyReducer = (state = initialValue, action) => {
   
    switch (action.type) {
        case CURRENCY_DATA:
            return {
                ...state,
                currencyDetails:action.payload
            };
        default:
            return state;
    }
}

export default CurrencyReducer;