import React, { useEffect, useState } from "react";
import AffiliateModal from "./AffiliateModal";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import logo from "../Assets/bot_img.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import isEmpty from "../lib/isEmpty";

import {
  REFERENCE_CODE
} from '../constant'

import { useSelector, useDispatch } from "react-redux";
import { getReffer } from "../api/api";
function CreateAcc() {
  const [affiliateModal, setAffiliateModal] = useState(false);
  const [affiliateCode, SetaffiliateCode] = useState('');
  let dispatch = useDispatch()
  const { code } = useParams();
  const history = useHistory()
  let location = useLocation()
  useEffect(() => {
    console.log(code, "code", location)
    if (!isEmpty(code)) {
      // history.push({ pathname: "/register", state: { code: code } })
      history.push('/connect-wallet')
      dispatch(setReference(code))
      if(!isEmpty(code)){
        getreffer()
      }
    }
  }, [code])
  const getreffer = async()=>{
    try{
      await getReffer({code:code})
    }catch(err){
      console.log("getReffer__err",err)
    }
  }
  const setReference = (data) => {
    try {
      return {
        type: REFERENCE_CODE,
        payload: data
      }
    } catch (err) {
      console.log(err, "setReference__err")
    }
  }
  return (
    <>
      <div className="create-acc">
        <div className="container py-5">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 col-9">
              <div className="">
                <div className="logo-img mb-2 mb-sm-2 mb-md-2 mb-lg-5 mb-lg-5">
                  <img src={logo} alt="logo" />
                </div>
                <Link
                  // to={{
                  //   pathname: "/connect-wallet",
                  //   state: { code: affiliateCode }
                  // }}
                  to="/connect-wallet"
                >
                  <Button
                    type="submit"
                    // size="lg"
                    className="btn common_green_Btn w-100 register-btn"
                  >
                    Register
                  </Button>
                </Link>
                <Link to="/login">
                  <Button
                    type="submit"
                    // size="md"
                    className="btn common_green_br_Btn w-100 login-btn my-3 my-sm-3 my-md-3 my-lg-5 my-xl-5"
                  >
                    Login
                  </Button>
                </Link>

                <button className="affiliate_link_btn" onClick={() => { setAffiliateModal(true) }}>Do you have an affiliate code?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {affiliateModal && <AffiliateModal
        setAffiliateCode={(value) => {
          SetaffiliateCode(value);
          dispatch(setReference(value))
        }}
        onDismiss={() => { setAffiliateModal(false) }}
        affiliateCode = {affiliateCode && affiliateCode}
      />}

    </>

  );
}

export default CreateAcc;
