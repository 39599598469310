import React, { useState,useEffect } from "react";
import Table from "react-bootstrap/Table";
import { AffiliateData } from '../api/api';

function AffiliateContent() {
  const [affiliate, setAffiliate] = useState({});

  useEffect(() => {
    affiliatedata()
  }, []);

  const affiliatedata = async()=>{


    var AffiliateContent = await AffiliateData();
  console.log("AffiliateContent",AffiliateContent.result);
  if(AffiliateContent.result.length>0){
    setAffiliate(AffiliateContent.result)

  }
 
  }
  
console.log("affiliate",affiliate);


  return (
    <div className="wallet-content">
      {/* <h3 className="my-3">Wallet</h3> */}
      <div className="row">
        <div className="col-xl-12 col-xxl-12 mb-3">
         

          {/* React Bootstrap Table */}

          <div className="trade-history-table-sec table_new mt-4">
            <Table bordered hover responsive className="text-center table_cont">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Amount</th>
                  {/* <th>Profit</th> */}
                </tr>
              </thead>
              <tbody>
                {affiliate.length > 0 && affiliate ? affiliate.map((val) => {
                return(
                  <>
                     {console.log("valvalvalsss", val)}
                  <tr>
                  <td>{val?.email[0]}</td>
                  
                  <td>{val?.totalAmount?.$numberDecimal ? val?.totalAmount?.$numberDecimal : val?.totalAmount}</td>
                </tr>
                  
                  </>
                )
             
                })
                  :
                  <tr>
                    <td colspan={2} className="text-center">
                      <div className="centerdiv"><p>There is no data</p></div></td></tr>
                }
              {/* <tr>
                <td>Demo User</td>
                <td>560 $</td>
                <td>12050 $</td>
              </tr> */}
              {/* <tr>
                <td>Demo User</td>
                <td>560 $</td>
                <td>12050 $</td>
              </tr>
              <tr>
                <td>Demo User</td>
                <td>560 $</td>
                <td>12050 $</td>
              </tr> */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

     
    </div>
  );
}

export default AffiliateContent;
