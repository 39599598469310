import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import SmallBot from "../Assets/Img/small_ bot.png";
import BogBot from "../Assets/Img/alien.png";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { priceConversion } from "../lib/useCalculation";
import isEmpty from "../lib/isEmpty";
import { key } from "../config/config";

//import action
import { PurcahseBot, getAuthToken, putpurchaseHistory , isUserExist ,checkMaxLimit} from "../api/api";
import { toastAlert } from "../toastalert";

import DepositNotfiy from "./DepositNotfiy";

//import lib
import { CalculateTimePeriod } from "../lib/dateTimeHelper";

import { CHAINS, Tokens } from "../config/config";
import { Approvetoken, checkIsApproved } from "../hooks/useERC20";
import { BuyBot, getAffilateFee, getOwner, getuserPlan ,checkuserPlan} from "../hooks/UseContract";

import { CONTRACT_PLANS } from "../constant";

import { useHistory } from 'react-router-dom';
import { getRefferAddress, getWalletAddress } from "../lib/localStorage";
import WalletConnectModal from "./WalletConnectModal";
function BotCard({
  show,
  // individualShow,
  handleClose,
  SelectBot,
  // handleIndividualShow,
  // handleIndividualClose,
  compoundingPeriod,
  setcompoundingPeriod,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [approve, setApprove] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [checkBot, setCheckBot] = useState();
  const [selectBot, setSelectBot] = useState({});
  const [BuyToken, setBuyToken] = useState("");
  const [DiscountPrice, SetDiscountPrice] = useState("");
  const [strikedays, setStrikedays] = useState("");
  const [expireddays, setExpireddays] = useState("");
  const [total, setTotal] = useState(0);
  const [individualShow, setIndividualShow] = useState(false);
  const { isAuth, userPlan } = useSelector((state) => state.user);
  const { walletData } = useSelector((state) => state.wallet);
  const { plans, Contractplans } = useSelector((state) => state.plan);
  const [loads, setLoads] = useState(false);
  const [ploads, setpLoads] = useState(false);
  const [ShowNotify, SetShowNotify] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false);
    setpLoads(false)
    setLoads(false)
    // window.location.href = "/dashboard";
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  // useEffect(()=>{
  //   MaxLimit()
  // },[])
  // const MaxLimit = async()=>{
  //   try{
  //     let {status,result} = await checkMaxLimit()
  //     if(status){
  //       return status
  //     }
  //     return status

  //   }catch(err){
  //     console.log(err,"MaxLimit__err")
  //   }
  // }
  useEffect(() => {
    if (show) {
      Total(0);
    }
  }, [show]);
  useEffect(() => {
    if (individualShow) {
      Total(0);
    }
  }, [individualShow]);

  const purchaseBot = async () => {
    try {
      let owner = await getOwner()
      let data = {
        planId: selectBot.planId,
        periodId: compoundingPeriod.pid,
        tokenAddress: CHAINS[0][`${BuyToken}`],
        referrer: getRefferAddress() ? getRefferAddress() : owner
      }
        // let isCheckuser = await MaxLimit()
        // if(isCheckuser){
        //   toastAlert("error","User max limit reach, sale was end", "purchaseBot");
        //   return false
        // }
      let {isExist,status} = await isUserExist({walletaddress:getWalletAddress()})
      if(!isExist){
        let isValid  =  await checkuserPlan(Contractplans,getWalletAddress())
        if(isValid){
          toastAlert("success","Don't get panic. your account was delete ,because 2 hours inactive ", "purchaseBot");
        }
        history.push('/register')
        return false
      }
      let Purchaseres = await BuyBot(data)
      if (Purchaseres) {
        toastAlert("success", 'Bot purchase successfully', "purchaseBot");
        handleCloseModal()
        let account = getWalletAddress()
        let AffiliateFee = await getAffilateFee()
        let refferAmount = parseFloat(selectBot._amount) / 10 ** 18 * ((AffiliateFee / 10 ** 18) / 100)
        let historyData = {
          walletaddress: account,
          planId: selectBot.planId,
          fee: parseFloat(selectBot._amount) / 10 ** 18,
          period: compoundingPeriod._duration,
          compoundingPeriodId: compoundingPeriod.pid,
          refferAmount: refferAmount
        }
        let buyPlanData = {
          walletaddress: account,
          planId: selectBot.planId,
          subscriptionFee: parseFloat(selectBot._amount) / 10 ** 18,
          buyToken: BuyToken,
          compoundingPeriodId: compoundingPeriod.pid,
          duration: compoundingPeriod._duration,
        }
        await PurcahseBot(buyPlanData)
        await putpurchaseHistory(historyData)
        Contractplans[selectBot.planId]['disable'] = true
        let PlanData = Contractplans
        dispatch({
          type:CONTRACT_PLANS,
          payload: PlanData
        })
        let respon = await getAuthToken({ walletaddress: account }, dispatch)
        history.push('/dashboard')
        if (respon) {
          history.push('/dashboard')
        }
      }
      // let data = {
      //   planId: selectBot._id,
      //   subscriptionFee: selectBot.subscriptionFee,
      //   compoundingPeriodId: compoundingPeriod._id,
      //   buyToken: BuyToken,
      //   totalAmount: total,
      // };
      // const { status, message } = await PurcahseBot(data);
      // if (status) {
      //   toastAlert("success", message, "purchaseBot");
      //   handleClose();
      //   handleCloseModal();
      //   setCheckBot();
      //   setSelectBot({});
      //   setcompoundingPeriod("");
      // } else {
      //   toastAlert("error", message, "purchaseBot");
      // }
    } catch (err) {
      console.log(err, "purchaseBot__err");
    }
  };

  const Total = async (i) => {
    try {
      let buyToken = selectBot.buyToken[i]
      let DiscountPrice = 0;
      if (selectBot._isDiscount) {
        var datas = (parseFloat(selectBot._amount) / 10 ** 18 * parseFloat(selectBot._DisPercent) / 10 ** 18) / 100;
        DiscountPrice = parseFloat(selectBot._amount) / 10 ** 18 - datas;
        SetDiscountPrice(DiscountPrice);
      }

      var strikedays = selectBot.strikeexpriedPeriod / 86400;
      setStrikedays(strikedays);

      var expireddays = selectBot.expriedPeriod / 86400;
      setExpireddays(expireddays);

      setBuyToken(buyToken);
      let converValue = await priceConversion("USDT", buyToken);
      let total =
        DiscountPrice == 0 ? parseFloat(selectBot._amount) / 10 ** 18 : DiscountPrice;
      setTotal(parseFloat(total));
      //   let Assets  =  walletData?.assets.find((val)=>(val.currencySymbol == selectBot.buyToken[i]))
      if (converValue == 0) {
        // if(Assets.balance < total){
        //   SetShowNotify(true)
        // }
        setTotal(parseFloat(total));
      } else {
        // if(Assets.balance < parseFloat(total) * parseFloat(converValue)){
        //   SetShowNotify(true)
        // }
        setTotal(parseFloat(total) * parseFloat(converValue));
      }
    } catch (err) {
      console.log(err, "purchaseBot__err");
    }
  };
  const CloseNotify = () => {
    try {
      SetShowNotify(false)
    } catch (err) {
      console.log(err, "handleCloseModal__err")
    }
  }

  const ApproveToken = async () => {
    try {
      let amount = selectBot._amount
      let Token = CHAINS[0][`${BuyToken}`]
      console.log(Token, "Token")
      const account = getWalletAddress();
      let isApproved = await checkIsApproved(account, Token, amount)
      if (isApproved) {
        setApprove(true)
        setLoads(false)
        return true
      }
      let Result = await Approvetoken(Token, amount)
      if (Result) {
        toastAlert("success", 'Approved Successfully', "purchaseBot");
        setApprove(true)
        setLoads(false)
      }
    } catch (err) {
      toastAlert("error", 'Try again', "purchaseBot");
      console.log(err, "ApproveToken")
    }
  }
  return (
    <div>
      {ShowNotify && <DepositNotfiy
        showModal={ShowNotify}
        handleCloseModal={() => { CloseNotify() }}
        currencySymbol={BuyToken}
      />}
      <div className="row botcard">
        {Contractplans && Contractplans.length > 0
          ? Contractplans.map((item, index) => {
            console.log(item, "Plan---item")
            if (item._isEnable) {
              let PlanData = plans.find((val) => val.planId == index)
              return (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                    <Card className={item?.disable ? "disabled_card" : ""}>
                      <Card.Body className="p-0">
                        <div className="p-4">
                          <div className="d-flex align-items-center justify-content-end">
                            <Form.Check
                              className="bot-check"
                              checked={index == checkBot ? true : false}
                              onChange={() => {
                                try {
                                  if (index == checkBot) {
                                    console.log(index == checkBot, "index == checkBot")
                                    setCheckBot();
                                    setSelectBot({});
                                    SelectBot({});
                                  } else {
                                    let data = { ...item }
                                    data.planId = index
                                    data = { ...data, ...PlanData }
                                    console.log(data, "selectBot")
                                    setCheckBot(index);
                                    setSelectBot(data);
                                    SelectBot(data);
                                  }

                                } catch (err) {
                                  console.log(err, "err")
                                }

                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center">
                            {PlanData?.image ? (
                              <img
                                src={`${key?.BACK_URL}/public/plan/${PlanData?.image}`}
                                alt="bigbot"
                                className="img-fluid big-bot"
                              />
                            ) : (
                              <img
                                src={BogBot}
                                alt="bigbot"
                                className="img-fluid big-bot"
                              />
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <p className="amount mb-3">
                              $ {parseFloat(item._amount) / 10 ** 18}
                            </p>
                            <p className="trade mb-2">
                              (trade with ${(parseFloat(item._minIvestment) / 10 ** 18).toFixed(0)}-${(parseFloat(item._maxIvestment) / 10 ** 18).toFixed(0)})
                            </p>
                          </div>
                        </div>
                        <div className="">
                          <Button
                            // onClick={handleShow}
                            onClick={() => {
                              let data = { ...item }
                              data.planId = index
                              data = { ...data, ...PlanData }
                              setSelectBot(data);
                              setIndividualShow(true);
                            }}
                            className="d-block common_green_Btn  w-100 activate-bot"
                          >
                            {PlanData?.name}
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              );
            }
          })
          : ""}
      </div>
      <Offcanvas
        show={show}
        className="purchase_bot_offcanvas"
        onHide={handleClose}
        placement="bottom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-center mx-auto canvas_tle">
            Your Order Summary
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="">
            {/* {console.log(selectBot,"selectBot")} */}
            {/* <h4 className="text-center mb-2 mb-sm-3 mb-md-3 mb-lg-4 mb-xl-4">
              Your Order Summary
            </h4> */}
            <div className="row">
              <div className="col-lg-8">
                <div>
                  <h6>Item Description</h6>
                  <div className="d-flex order_summary_popup justify-content-between mt-3 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5 align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="order_summary_bot_img">
                        <img src={BogBot} alt="big-bot" />
                      </div>
                      <div className="ps-2 ps-sm-2 ps-md-3 ps-lg-4 ps-xl-5">
                        <p className="duration p-0 m-0">
                          {/* Compounding Period - {CalculateTimePeriod(compoundingPeriod?._duration)} */}
                          Compounding Period - {(compoundingPeriod.duration)}{" "}
                          {compoundingPeriod?.Timeperiod}
                        </p>
                        <p className="trade mb-0">
                          (trade with ${parseFloat(selectBot?._maxIvestment) / 10 ** 18}-{parseFloat(selectBot?._minIvestment) / 10 ** 18})
                        </p>
                        <p className="trade mb-0 text-danger">
                          Locking Period -
                          {/* {CalculateTimePeriod(selectBot?._validity)} */}
                          <s>{strikedays}</s> {expireddays}{" "}
                          days{" "}
                        </p>
                        <div className="d-flex justify-content-start mt-2">
                          {selectBot && isEmpty(selectBot?.buyToken)
                            ? ""
                            : selectBot?.buyToken.map((val, index) => {
                              return (
                                <Button
                                  className="common_green_Btn purchase_bot_custom_btn me-2"
                                  onClick={() => {
                                    Total(index);
                                  }}
                                >
                                  {val}
                                </Button>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 price-dtls mt-4 mt-lg-0">
                <Card className="h-100">
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="row align-items-start ">
                      <div className="col-6 d-flex justify-content-start">
                        <p className="bot_subscription_txt mb-0 prc_name">
                          Original Price
                        </p>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <p className="amount mb-0 amt_prc">
                          $ <s>{selectBot.strikeFee}</s>{" "}
                          {selectBot.subscriptionFee}{" "}
                          {/* $ {parseFloat(selectBot._amount)/10**18} */}
                        </p>
                      </div>
                    </div>
                    {selectBot?._isDiscount ? (
                      <div className="row  align-items-start mt-3">
                        <div className="col-6 d-flex justify-content-start">
                          <p className="bot_subscription_txt mb-0 prc_name">
                            Discounted Price
                          </p>
                        </div>
                        <div className="col-6 d-flex justify-content-start">
                          <p className="amount mb-0 amt_prc">
                            $ <s>{parseFloat(selectBot._amount) / 10 ** 18}</s> {DiscountPrice}
                            {""}
                            <span>({parseFloat(selectBot._DisPercent) / 10 ** 18}% off)</span>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="row  align-items-start mt-3">
                      <div className="col-6 d-flex justify-content-start">
                        <p className="bot_subscription_txt mb-0 prc_name">
                          Total Price in USD
                        </p>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <p className="amount mb-0 amt_prc">
                          ${" "}
                          {selectBot.isDiscount
                            ? DiscountPrice
                            : parseFloat(selectBot._amount) / 10 ** 18}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row align-items-start mt-3">
                      <div className="col-6 d-flex justify-content-start">
                        <p className="bot_subscription_txt mb-0 prc_name">
                          Total Price in {BuyToken}
                        </p>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <p className="amount mb-0 amt_prc">
                          {BuyToken} {parseFloat(total).toFixed(4)}{" "}
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              {/* <div className="d-flex justify-content-end my-2 my-sm-3 my-md-3 my-lg-3 my-xl-3">
                {Tokens && isEmpty(Tokens)
                  ? ""
                  : Tokens.map((val, index) => {
                      return (
                        <Button
                          className="common_green_Btn purchase_bot_custom_btn me-2"
                          onClick={() => {
                            Total(val);
                          }}
                        >
                          {val}
                        </Button>
                      );
                    })}
              </div> */}
              {/* <hr className="mt-3 mb-1 mt-sm-3 mb-sm-2 mt-md-2 mb-md-2 mt-lg-2 mb-lg-2"></hr>
              <div className="d-flex justify-content-between  ">
                <p className="total m-0">Total</p>
                <p className="amount m-0">
                  {BuyToken} {parseFloat(total).toFixed(4)}
                </p>
              </div> */}
              <hr className="mt-4 mt-lg-5 mb-2"></hr>
              <div className="row mt-3 mt-sm-3 mt-md-4 mt-lg-5 mt-xl-5 d-flex justify-content-center align-items-center">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8">
                  <Button
                    onClick={handleShowModal}
                    className="purchase_btn common_green_Btn d-block w-100"
                  >
                    Purchase
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* individual item click */}

      <Offcanvas
        show={individualShow}
        className="purchase_bot_offcanvas"
        onHide={() => setIndividualShow(false)}
        placement="bottom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-center mx-auto canvas_tle">
            {" "}
            Bot Details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mt-lg-2 mt-xxl-3">
            <div className="row">
              <div className="col-12 col-xl-8">
                <div>
                  <h6>Item Description</h6>
                  <div className="d-flex order_summary_popup justify-content-between mt-3 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5 align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="order_summary_bot_img">
                        <img src={BogBot} alt="big-bot" />
                      </div>
                      <div className="ps-2 ps-sm-2 ps-md-3 ps-lg-4 ps-xl-5">
                        <p className="trade mb-0">
                          (trade with ${parseFloat(selectBot._maxIvestment) / 10 ** 18}-{parseFloat(selectBot._minIvestment) / 10 ** 18})
                        </p>
                        <p className="trade mb-0 text-danger">
                          Locking Period -
                          {/* {CalculateTimePeriod(selectBot._validity)} */}
                          <s>{strikedays}</s> {expireddays}{" "}
                          days{" "}
                        </p>
                        <div className="d-flex justify-content-start mt-2">
                          {selectBot && isEmpty(selectBot?.buyToken)
                            ? ""
                            : selectBot?.buyToken.map((val, index) => {
                              return (
                                <Button
                                  className="common_green_Btn purchase_bot_custom_btn me-2"
                                  onClick={() => {
                                    Total(val);
                                  }}
                                >
                                  {val}
                                </Button>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-4 price-dtls mt-4 mt-xl-0">
                <Card className="h-100">
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <div className="row align-items-start ">
                      <div className="col-6 d-flex justify-content-start">
                        <p className="bot_subscription_txt mb-0 prc_name">
                          Original Price
                        </p>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <p className="amount mb-0 amt_prc">
                          $ <s>{selectBot.strikeFee}</s>{" "}
                          {selectBot.subscriptionFee}{" "}
                          {/* $ {parseFloat(selectBot._amount)/10**18} */}
                        </p>
                      </div>
                    </div>
                    {selectBot._isDiscount ? (
                      <div className="row  align-items-start mt-3">
                        <div className="col-6 d-flex justify-content-start">
                          <p className="bot_subscription_txt mb-0 prc_name">
                            Discounted Price
                          </p>
                        </div>
                        <div className="col-6 d-flex justify-content-start ">
                          <p className="amount mb-0 amt_prc">
                            $ <s>{parseFloat(selectBot?._amount) / 10 ** 18}</s>{" "}
                            {DiscountPrice}
                            {""}
                            <span>({parseFloat(selectBot?._DisPercent) / 10 ** 18}% off)</span>
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row  align-items-start mt-3">
                      <div className="col-6 d-flex justify-content-start">
                        <p className="bot_subscription_txt mb-0 prc_name">
                          Total Price in USD
                        </p>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <p className="amount mb-0 amt_prc">
                          ${" "}
                          {selectBot._isDiscount
                            ? DiscountPrice
                            : parseFloat(selectBot._amount) / 10 ** 18}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row align-items-start mt-3">
                      <div className="col-6 d-flex justify-content-start">
                        <p className="bot_subscription_txt mb-0 prc_name">
                          Total Price in {BuyToken}
                        </p>
                      </div>
                      <div className="col-6 d-flex justify-content-start">
                        <p className="amount mb-0 amt_prc">
                          {BuyToken} {parseFloat(total).toFixed(4)}{" "}
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        className="purchase_bot_modal"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{approve ? "Purchase to claim your swipe token." : "Please Approve the amount"}</Modal.Body>
        <Modal.Footer>

          {approve ? <Button
            //  disabled={loads}
            onClick={() => {
              purchaseBot();
              setpLoads(true)
            }}
            className="common_green_Btn"
          >
            {ploads ?
              "Loading..." :
              'purchase'
            }
          </Button> :
            <Button
              disabled={loads}
              onClick={() => {
                ApproveToken()
                setLoads(true)
              }}
              className="common_green_Btn"
            >
              {loads ?
                "Loading..." :
                "Approve"}
            </Button>
          }

          {/* <button class="btn btn-success" type="button" disabled>
          <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"> </span>
             Loading...
        </button> */}
          {/* {isAuth ? (
            <Button
              onClick={() => {
                purchaseBot();
              }}
              className="common_green_Btn"
            >
              Done
            </Button>
          ) : (
            <Link to="/login">
              <Button onClick={handleCloseModal} className="common_green_Btn">
                Login
              </Button>
            </Link>
          )} */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BotCard;
