import React, { useEffect, useState } from "react";
import {
    useParams,
    useNavigate,
    useLocation,
    useHistory
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
// import action
import {
    userEmailActivation,
    verifyOldEmail,
    verifyNewEmail

} from '../api/api';



// import lib
import { toastAlert } from "../toastalert";

const EmailVerification = (props) => {
    const { authToken } = useParams();
    console.log(authToken,"authToken")
    const { t, i18n } = useTranslation();
    const[state,setState] =useState(true)
    // const navigate = useNavigate()
    const history = useHistory()
    const location = useLocation();

    // state
    const [page, setPage] = useState('loading')

    // function
    const emailActivation = async () => {
        const { status, message } = await userEmailActivation({ userId: authToken });
        if (status) {
            history.push("/login")
            toastAlert('success', message, 'emailActivation', 'TOP_RIGHT');
        } else if(!status) {
            history.push("/login")
            toastAlert('error', message, 'emailActivation', 'TOP_RIGHT');
        }
        else {
            setPage('error');
        }
    }

    const verifOldEmail = async () => {
        console.log("authToken1111",authToken);
        const { status, message } = await verifyOldEmail({ token: authToken });
        if (status) {
            history.push("/login")
            toastAlert('success', message);
        } else {
            setPage('error');
        }
    }

    const verifNewEmail = async () => {
      if(state){
        setState(false)
        console.log("authToken2222",authToken);
        const { status, message } = await verifyNewEmail({ token: authToken });
        if (status) {
            history.push("/login")
            toastAlert('success', message);
            setState(false)
        } else {
            setPage('error');
        }
      }
    }

    useEffect(() => {
        let pathname = location.pathname;
        if (pathname == '/email-verification/' + authToken) {
            emailActivation();
        }
         else if (pathname == '/verify-old-email/' + authToken) {
            verifOldEmail();
        } else if (pathname == '/verify-new-email/' + authToken) {

            verifNewEmail();
            
        } 
        // else if (pathname == '/withdraw-fiat-verification/' + authToken) {
        //     acceptFiatRequest()
        // } else if (pathname == '/withdraw-coin-verification/' + authToken) {
        //     acceptCoinRequest()
        // } else if (pathname == '/withdraw-approve/' + authToken) {
        //     withdrawApprove()
        // } else if (pathname == '/withdraw-cancel/' + authToken) {
        //     WithdrawCancel()
        // }
    }, [])

    return (
        <>
            {
                page == 'loading' && <p>{t('LOADING')}</p>
            }
            {
                page == 'error' && <p>{t('INVALID_URL')}</p>
            }
        </>
    )


}

export default EmailVerification;