import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import SmallBot from "../Assets/Img/small_ bot.png";
import BogBot from "../Assets/Img/alien.png";
import Form from "react-bootstrap/Form";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import BotCard from "./BotCard";
import { compoundlistingData } from "../api/api";
import isEmpty from "../lib/isEmpty";

//import actions
import { getPlans } from "../api/api";

//import Buycontract
import { getTotalPeriods, getTotalPlans } from "../hooks/UseContract";

//import lib
import { CalculateTimePeriod } from "../lib/dateTimeHelper";

import { MetamaskDisconnect } from "../hooks/useWallet";

import { getWalletAddress } from "../lib/localStorage";
import WalletConnectModal from "./WalletConnectModal";
function PurchaseBot() {
  const [list, setList] = useState([]);

  // const [ContractPeriod, setContractPeriod] = useState([]);

  const [selectBot, setSelectBot] = useState({});
  const [compoundingPeriod, setcompoundingPeriod] = useState({});
  // const [compoundingPeriodDu, setcompoundingPeriodDu] = useState({});
  const { walletaddress } = useSelector((state) => state.wallet);
  const { ContractPeriod } = useSelector((state) => state.compound);

  const OnSelectBot = (val) => {
    try {
      setSelectBot(val);
    } catch (err) {
      console.log(err, "OnSelectBot");
    }
  };
  const [show, setShow] = useState(false);
  const [showWallet, SetShowWallet] = useState(false);
  // const [individualShow, setIndividualShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // const handleIndividualShow = () => {
  //   setIndividualShow(true);
  // };
  // const handleIndividualClose = () => {
  //   setIndividualShow(false);
  // };

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("navbar_false");
    document.getElementsByTagName("body")[0].classList.add("navbar_true");
    compoundlisting();
    // CompoundList();
  }, []);
  // const CompoundList = async () => {
  //   try {
  //     let data = await getTotalPeriods();
  //     console.log(data, "CompoundList");
  //     setContractPeriod(data);
  //   } catch (err) {
  //     console.log(err, "CompoundList__err");
  //   }
  // };
  const compoundlisting = async () => {
    var list = await compoundlistingData();
    if (list?.result?.data) {
      setList(list?.result?.data);
    }
  };

  const ComputingPeriods = [
    {
      duration: "24hrs",
    },
    {
      duration: "2 Weeks",
    },
    {
      duration: "1 Months",
    },
    {
      duration: "3 Months",
    },
    {
      duration: "6 Months",
    },
    {
      duration: "1 Year",
    },
  ];

  return (
    <>
      <div className="purchase-bot">
        {showWallet && (
          <WalletConnectModal
            onDismiss={() => {
              SetShowWallet(false);
            }}
          />
        )}
        <div className="container py-5">
          <BotCard
            show={show}
            // individualShow={individualShow}
            handleClose={handleClose}
            SelectBot={OnSelectBot}
            // handleIndividualShow={handleIndividualShow}
            // handleIndividualClose={handleIndividualClose}
            compoundingPeriod={compoundingPeriod}
            setcompoundingPeriod={setcompoundingPeriod}
          />
          <div className="computing_period">
            <h6 className="">Compounding Period</h6>
            <div className="row mt-4">
              {ContractPeriod &&
                ContractPeriod?.map((item, index) => {
                  {
                    console.log(item, ContractPeriod, "item+++++++++++");
                  }
                  let compoundData = list.find((val) => val.pid == index);
                  return (
                    <>
                      <div className="col-xl-2 col-lg-2 mb-3 col-md-4 col-sm-4 col-6">
                        <div className="d-flex align-items-center">
                          {ContractPeriod ? (
                            <Form.Check
                              className="period-check"
                              onChange={() => {
                                if (compoundingPeriod.pid == index) {
                                  setcompoundingPeriod({});
                                } else {
                                  let Data = { ...item };
                                  Data.pid = index;
                                  Data = { ...Data, ...compoundData };
                                  setcompoundingPeriod(Data);
                                }
                              }}
                              // checked={
                              //   compoundingPeriod._id == item?._id ? true : false
                              // }
                              checked={
                                compoundingPeriod.pid == index ? true : false
                              }
                            />
                          ) : (
                            <></>
                          )}
                          <span className="ps-3">
                            {/* {CalculateTimePeriod(item?._duration)} */}
                            {compoundData?.duration} {compoundData?.Timeperiod}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}

              {/* {ComputingPeriods.map((item) => {
                return (
                  <>
                    <div className="col-xl-2 col-lg-2 mb-3 col-md-4 col-sm-4 col-6">
                      <div className="d-flex align-items-center">
                        <Form.Check className="period-check" onChange={() => {
                          setcompoundingPeriod(item.duration)
                        }}
                          checked={compoundingPeriod == item.duration ? true : false}
                        />
                        <span className="ps-3">{item.duration}</span>
                      </div>
                    </div>
                  </>
                );
              })} */}
            </div>
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-xl-5">
                {getWalletAddress() ? (
                  <Button
                    className="proceed_btn common_green_Btn w-100"
                    disabled={
                      !isEmpty(compoundingPeriod) && !isEmpty(selectBot)
                        ? false
                        : true
                    }
                    onClick={handleShow}>
                    Proceed
                  </Button>
                ) : (
                  <Button
                    className="proceed_btn common_green_Btn w-100"
                    //  disabled={
                    //    !isEmpty(compoundingPeriod) && !isEmpty(selectBot)
                    //      ? false
                    //      : true
                    //  }
                    onClick={() => {
                      SetShowWallet(true);
                    }}>
                    Connect wallet
                  </Button>
                )}
                {/* <Button
                className="proceed_btn common_green_Btn w-100"
                onClick={()=>{
                  MetamaskDisconnect()
                 }}
                >
                  disconnect
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PurchaseBot;
