import React from "react";
import swipeacademy from "../Assets/pdf/swipeacademy.pdf";
import { FaDownload } from "react-icons/fa6";
import {key} from '../config/config'
function VideoSec() {
  const VideoSrc = `${key.BACK_URL}/public/video/Record.mp4`
    // "https://player.vimeo.com/progressive_redirect/playback/682048671/rendition/360p?loc=external&oauth2_token_id=57447761&signature=5be12c7ba41b540b1bb6e8ff3cb8f859e9381ece088946bb8257fe3da7201f79";
  return (
    <>
      <div className="video_sec">
        <div className="container py-5">
          <h2 className="">How to buy</h2>
          <div className="video_display mt-4">
            <video width="" height="" controls src={VideoSrc} />
          </div>
          <div className="mt-4">
            <h4 className="">Download Document</h4>
            <a
              className="btn bordersandbtn mt-3"
              href={swipeacademy}
              target="_blank"
              download
            >
              <span className="d-flex align-items-center">
                {" "}
                <span className="download_icon me-1">
                  <FaDownload size={16} />
                </span>
                Swipe Academy
              </span>
            </a>
            {/* <button className="download_btn btn-primary green-btn">
              Download
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoSec;
