import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import CoinWallet from "../Assets/Img/CoinWallet.png";
import MetaMask from "../Assets/Img/MetaMask.png";
import TrustWallet from "../Assets/Img/TrustWallet.png";
import WalletConnect from "../Assets/Img/WalletConnect.png";
import Pancakessap from "../Assets/Img/Pancakessap.png";
import native from "../Assets/Img/native_wallet.png";

import GuidePdf from "../Assets/pdf/SwipeArbitrage_user.pdf";

//import hooks
import { MetamaskWallet, WalletConnectfun } from "../hooks/useWallet";
import { WalletConnectTron } from "../hooks/TronWeb";

//import action
import { loginViaWallet, getAuthToken } from "../api/api";

//import contract
import { getuserPlan } from "../hooks/UseContract";

//selector
import { useSelector } from "react-redux";

import { checkuserPlan } from "../hooks/UseContract";
import { CHAINS } from "../config/config";

function ConnectWallet() {
  const history = useHistory();
  let dispatch = useDispatch();
  // const [walletAddress,setWalletAddress] = useState("")
  const { plans, Contractplans } = useSelector((state) => state.plan);
  const OnSetWalletAddress = async (account) => {
    try {
      let result = await loginViaWallet({ walletaddress: account }, dispatch);
      console.log("🚀 ~ OnSetWalletAddress ~ result:", result);
      if (result.status) {
        let isValidUser = await checkuserPlan(Contractplans, account);
        console.log("🚀 ~ OnSetWalletAddress ~ isValidUser:", isValidUser);
        if (isValidUser) {
          await getAuthToken({ walletaddress: account }, dispatch);
          history.push("/dashboard");
        } else {
          await getAuthToken({ walletaddress: account }, dispatch);
          history.push("/launchpad");
        }
      } else {
        history.push("/register");
      }
    } catch (err) {
      console.log(err, "OnSetWalletAddress__Err");
    }
  };
  const MetamaskConnectWallet = async () => {
    try {
      const connection = await MetamaskWallet(OnSetWalletAddress, dispatch);
      // console.log("connect_connect", connection)
      // if (connection) {
      //   history.push("/register")
      // }
    } catch (e) {
      console.log("MetamaskConnectWallet", e);
    }
  };
  const ConnectWalletConnect = async () => {
    try {
      const connection = await WalletConnectfun(OnSetWalletAddress, dispatch);
      // console.log("connect_connect", connection)
      // if (connection) {
      //   history.push("/register")
      // }
    } catch (e) {
      console.log("MetamaskConnectWallet", e);
    }
  };

  // const TronWalletConnect =  async()=>{
  //   try{
  //     let connection = await WalletConnectTron(OnSetWalletAddress,dispatch)
  //     if (connection) {
  //       history.push("/register")
  //     }
  //   }catch(err){
  //     console.log(err,"TronWalletConnect__Err")
  //   }
  // }
  return (
    <div className="connect_wallet">
      <div className="container py-5">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-9 col-9">
            <h3 className="header text-center">Connect Wallet</h3>
            <h4 className="header text-center">Network : {CHAINS[0].NAME} </h4>
            <div className="mt-3 w-100">
              {/* <Link to="/purchase-bot"> */}
              {/* <Button className="btn btn-block w-100 cmn-btn">
                  <div className="img_sec">
                    <img
                      src={TrustWallet}
                      alt="Trust Wallett"
                      className="img-fluid"
                    />
                    <span className="ps-3">Trust Wallet</span>
                  </div>
                </Button> */}
              {/* </Link> */}
              {/* <Link to="/purchase-bot"> */}
              <Button
                className="btn btn-block w-100 cmn-btn"
                onClick={() => {
                  MetamaskConnectWallet();
                }}>
                <div className="img_sec">
                  <img src={MetaMask} alt="Metamask" className="img-fluid" />
                  <span className="ps-3">Metamask for Desktop</span>
                </div>
              </Button>
              {/* <Button className="btn btn-block w-100 cmn-btn" onClick={() => { MetamaskConnectWallet() }}>
                <div className="img_sec">
                  <img src={MetaMask} alt="Metamask" className="img-fluid" />
                  <span className="ps-3">MetaMask for Mobile</span>
                </div>
              </Button> */}

              <Button
                className="btn btn-block w-100 cmn-btn"
                onClick={() => {
                  MetamaskConnectWallet();
                }}>
                <div className="img_sec">
                  <img src={native} alt="Metamask" className="img-fluid" />
                  <span className="ps-3">Native D-app wallet</span>
                </div>
              </Button>
              {/* <Button className="btn btn-block w-100 cmn-btn" onClick={() => { TronWalletConnect() }}>
                <div className="img_sec">
                  <img src={MetaMask} alt="Metamask" className="img-fluid" />
                  <span className="ps-3">Tron</span>
                </div>
              </Button> */}
              {/* </Link> */}
              {/* <Link to="/purchase-bot"> */}
              {/* <Button className="btn btn-block w-100 cmn-btn">
                  <div className="img_sec">
                    <img
                      src={CoinWallet}
                      alt="Coin Wallet"
                      className="img-fluid"
                    />
                    <span className="ps-3">Coinbase</span>
                  </div>
                </Button> */}
              {/* </Link> */}
              {/* <Link to="/purchase-bot"> */}
              {/* <Button className="btn btn-block w-100 cmn-btn">
                  <div className="img_sec">
                    <img
                      src={Pancakessap}
                      alt="Pancakessap"
                      className="img-fluid"
                    />
                    <span className="ps-3">Pancakeswap</span>
                  </div>
                </Button> */}
              {/* </Link> */}
              {/* <Link to="/purchase-bot"> */}
              <Button
                className="btn btn-block w-100 cmn-btn"
                onClick={() => {
                  ConnectWalletConnect();
                }}>
                <div className="img_sec">
                  <img
                    src={WalletConnect}
                    alt="wallet connect"
                    className="img-fluid"
                  />
                  <span className="ps-3">Mobile wallet</span>
                </div>
              </Button>
              <div className="text-center mt-4">
                <a
                  className="green-btn mob_btn register_custom_btn w-100 btn btn-primary py-3 bnt_down"
                  href={GuidePdf}
                  target="_blank"
                  download
                  // onClick={()=>{ConnectWalletConnect()}}
                >
                  {/* <img
                    src={WalletConnect}
                    alt="wallet connect"
                    className="img-fluid"
                  /> */}
                  <span className="ps-3">How to connect</span>
                </a>
                {/* </Link> */}
              </div>

              {/* <Link to="/purchase-bxot">
                <Button className="btn btn-block w-100 common_green_Btn show-more mt-3">
                  Show more
                </Button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectWallet;
