// import constant
import {
    COMPOUND_DATA,
    
} from '../constant';

const initialValue = {
    ContractPeriod:[]
}

//  console.log("initialValue----->>>",initialValue);
const plan = (state = initialValue, action) => {

    switch (action.type) {
        case COMPOUND_DATA:
            return {
                ...state,
                ContractPeriod: action.payload
            }
           
        default:
            return state;
    }
}

export default plan;