import React, { useEffect, useState } from "react";
import { getPoolInfoLimit, getPoolLength, getUser } from "../hooks/useStake";
import { getAccount } from "../hooks/useWeb3";
import LockedStackCont from "../components/LockedStackCont";

const LockedStake = () => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("navbar_false");
    document.getElementsByTagName("body")[0].classList.add("navbar_true");
  }, []);
  const [poolLimit, setPoolLimit] = useState([]);
  const [poolLength, setPoolLength] = useState(0);
  const [accountInfo, setAccountInfo] = useState(getAccount());
  // const [accountInfo, setAccountInfo] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var data = await getPoolLength();
    if (data) setPoolLength(data);
    var pool = await getPoolInfoLimit(0, data);
    console.log(pool, data, "pool");
    if (pool) {
      setPoolLimit(pool);
    }
  };
  return (
    <div className="lockedstake bg-white text-black">
      <div className="container container-theme mt-4">
        <div className="br-14 bgtheme text-dark p-sm-5 p-4 text-center bannerbox d-flex align-items-center justify-content-center">
          <h1 className="innerheading">Locked Staking</h1>
        </div>
        <div className="row mb-5">
          {poolLimit &&
            poolLimit?.map((val, index) => (
              <LockedStackCont
                datas={val}
                pid={index}
                accountInfo={accountInfo}
              />
            ))}
          {/* <LockedStackCont datas={val} pid={index} accountInfo={accountInfo} /> */}
        </div>
      </div>
    </div>
  );
};

export default LockedStake;
