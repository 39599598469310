export const BuyContractABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_poolID", type: "uint256" },
      { internalType: "uint256", name: "_planID", type: "uint256" },
    ],
    name: "Redeem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "SWIPE",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "Staker",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "_periods",
    outputs: [
      { internalType: "uint256", name: "_duration", type: "uint256" },
      { internalType: "bool", name: "_isAutoCompound", type: "bool" },
      { internalType: "bool", name: "_isActive", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "_plans",
    outputs: [
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "uint256", name: "_validity", type: "uint256" },
      { internalType: "uint256", name: "_minIvestment", type: "uint256" },
      { internalType: "uint256", name: "_maxIvestment", type: "uint256" },
      { internalType: "bool", name: "_isDiscount", type: "bool" },
      { internalType: "uint256", name: "_DisPercent", type: "uint256" },
      { internalType: "bool", name: "_isEnable", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "_tokens",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    name: "_userBot",
    outputs: [
      { internalType: "uint256", name: "_planID", type: "uint256" },
      { internalType: "uint256", name: "_periodsID", type: "uint256" },
      { internalType: "uint256", name: "_expiredOn", type: "uint256" },
      { internalType: "uint256", name: "_investedAmount", type: "uint256" },
      { internalType: "uint256", name: "_profit", type: "uint256" },
      { internalType: "uint256", name: "_buyTime", type: "uint256" },
      { internalType: "bool", name: "_enable", type: "bool" },
      { internalType: "bool", name: "_isExpriy", type: "bool" },
      { internalType: "bool", name: "_isClaimed", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_duration", type: "uint256" },
      { internalType: "bool", name: "autoCompound", type: "bool" },
      { internalType: "bool", name: "isActive", type: "bool" },
    ],
    name: "addPeriod",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_planAmount", type: "uint256" },
      { internalType: "uint256", name: "_validity", type: "uint256" },
      { internalType: "uint256", name: "_min", type: "uint256" },
      { internalType: "uint256", name: "_max", type: "uint256" },
      { internalType: "bool", name: "_isDiscount", type: "bool" },
      { internalType: "uint256", name: "_DisPercent", type: "uint256" },
      { internalType: "bool", name: "_isEnable", type: "bool" },
    ],
    name: "addPlan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "bool", name: "_allowed", type: "bool" },
    ],
    name: "addToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_planID", type: "uint256" }],
    name: "botsubscribe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "geUnlockTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getAffilateFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getLastPeriodIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getLastPlanIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_pid", type: "uint256" }],
    name: "getPeriod",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "_duration", type: "uint256" },
          { internalType: "bool", name: "_isAutoCompound", type: "bool" },
          { internalType: "bool", name: "_isActive", type: "bool" },
        ],
        internalType: "struct BUYSWIPE.Periods",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_planID", type: "uint256" }],
    name: "getPlan",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "_amount", type: "uint256" },
          { internalType: "uint256", name: "_validity", type: "uint256" },
          { internalType: "uint256", name: "_minIvestment", type: "uint256" },
          { internalType: "uint256", name: "_maxIvestment", type: "uint256" },
          { internalType: "bool", name: "_isDiscount", type: "bool" },
          { internalType: "uint256", name: "_DisPercent", type: "uint256" },
          { internalType: "bool", name: "_isEnable", type: "bool" },
        ],
        internalType: "struct BUYSWIPE.Plan",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "getTokenPerCrypto",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTotalPeriod",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "_duration", type: "uint256" },
          { internalType: "bool", name: "_isAutoCompound", type: "bool" },
          { internalType: "bool", name: "_isActive", type: "bool" },
        ],
        internalType: "struct BUYSWIPE.Periods[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getTotalPlan",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "_amount", type: "uint256" },
          { internalType: "uint256", name: "_validity", type: "uint256" },
          { internalType: "uint256", name: "_minIvestment", type: "uint256" },
          { internalType: "uint256", name: "_maxIvestment", type: "uint256" },
          { internalType: "bool", name: "_isDiscount", type: "bool" },
          { internalType: "uint256", name: "_DisPercent", type: "uint256" },
          { internalType: "bool", name: "_isEnable", type: "bool" },
        ],
        internalType: "struct BUYSWIPE.Plan[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "user", type: "address" },
      { internalType: "uint256", name: "_planID", type: "uint256" },
    ],
    name: "getuserPlan",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "_planID", type: "uint256" },
          { internalType: "uint256", name: "_periodsID", type: "uint256" },
          { internalType: "uint256", name: "_expiredOn", type: "uint256" },
          { internalType: "uint256", name: "_investedAmount", type: "uint256" },
          { internalType: "uint256", name: "_profit", type: "uint256" },
          { internalType: "uint256", name: "_buyTime", type: "uint256" },
          { internalType: "bool", name: "_enable", type: "bool" },
          { internalType: "bool", name: "_isExpriy", type: "bool" },
          { internalType: "bool", name: "_isClaimed", type: "bool" },
        ],
        internalType: "struct BUYSWIPE.UserBot",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "time", type: "uint256" }],
    name: "lock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "parentOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pricePerUSD",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_planID", type: "uint256" },
      { internalType: "uint256", name: "_periodID", type: "uint256" },
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "address", name: "_referrer", type: "address" },
    ],
    name: "purchaseBot",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_fee", type: "uint256" }],
    name: "setAffilateFee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_pid", type: "uint256" },
      { internalType: "uint256", name: "_duration", type: "uint256" },
      { internalType: "bool", name: "autoCompound", type: "bool" },
      { internalType: "bool", name: "isActive", type: "bool" },
    ],
    name: "setPeriod",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_planID", type: "uint256" },
      { internalType: "uint256", name: "_planAmount", type: "uint256" },
      { internalType: "uint256", name: "_validity", type: "uint256" },
      { internalType: "uint256", name: "_min", type: "uint256" },
      { internalType: "uint256", name: "_max", type: "uint256" },
      { internalType: "bool", name: "_isDiscount", type: "bool" },
      { internalType: "uint256", name: "_DisPercent", type: "uint256" },
      { internalType: "bool", name: "_isEnable", type: "bool" },
    ],
    name: "setPlan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_price", type: "uint256" }],
    name: "setPricePerUSD",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_staker", type: "address" }],
    name: "setStaker",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_staker", type: "address" }],
    name: "setSwipe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "unlock",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
    name: "withDrawBNB",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "address", name: "_user", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "withDrawFor",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_token", type: "address" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "withDrawToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
