import React from "react";

function CustomLoader() {
  return (
    <div className="loader_sec">
      <span class="custom_loader"></span>
    </div>
  );
}

export default CustomLoader;
